import {
  boolean, object, ObjectSchema, string
} from 'yup';
import { RefererArray, RestLoginInput } from '../hooks/interface/auth/Rest';
import castFormTag from '../utils/FormTag';

export const LoginForm = castFormTag<RestLoginInput>();

const loginSchema:ObjectSchema<RestLoginInput> = object({
  email: string().required('Please enter your email.'),
  password: string().required('Please enter your password.'),
  remember: boolean().default(false),
  referer: string().oneOf(RefererArray).required()
}).strict(true);

export default loginSchema;
