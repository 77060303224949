/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore } from 'zustand';

export interface LoadingContainerStructure {
  isLoading: boolean;
  flagLoading: (value:boolean) => void;
}
export const LoadingContainer = createStore<LoadingContainerStructure>((set) => ({
  isLoading: false,
  flagLoading(value) {
    set({ isLoading: value });
  },
}));
