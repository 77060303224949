import styled from '@emotion/styled';
import { FormInputProps } from '../../../utils/FormUtils';

export const InputFlexDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex : 1;
`;
export const BlockLabel = styled.label`
  display: block;
`;
function FormHiddenInput<Data>({
  name,
  register,
  errors,
  ...props
}: FormInputProps<Data>) {
  return (
    <input type="hidden" {...register} id={name} value={props.value} />
  );
}

export default FormHiddenInput;
