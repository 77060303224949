function AppleLogoSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 39} height={props.height ?? 39} viewBox="0 0 39 39" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="39" height="39" rx="8" />
      <path d="M20.6089 13.8846C19.93 13.8846 18.8794 13.1211 17.7731 13.148C16.3122 13.1671 14.9731 13.9859 14.2204 15.2829C12.7044 17.886 13.8294 21.7308 15.3082 23.8466C16.0336 24.8765 16.8894 26.0353 18.0237 25.9992C19.1122 25.9531 19.5204 25.3001 20.8416 25.3001C22.1528 25.3001 22.5245 25.9992 23.6774 25.9715C24.8497 25.9531 25.5937 24.9232 26.3098 23.8834C27.1376 22.6877 27.4814 21.5282 27.5 21.4645C27.4721 21.4552 25.2213 20.5996 25.1941 18.0241C25.1755 15.8708 26.9701 14.8409 27.0539 14.7948C26.0305 13.3144 24.4594 13.1487 23.9101 13.1118C22.4779 13.0013 21.2784 13.8846 20.6089 13.8846ZM23.0279 11.7129C23.6316 10.9961 24.0304 9.99378 23.9194 9C23.0551 9.03683 22.0131 9.5702 21.3901 10.2877C20.8316 10.9224 20.3489 11.9438 20.4785 12.9185C21.4367 12.9921 22.4228 12.4311 23.0272 11.7136" fill="white" />
    </svg>
  );
}

export default AppleLogoSvg;
