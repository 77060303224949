export type EnvMode = 'local' | 'dev' | 'prod';

const location = window?.location?.host || '';
export const DEPLOY_MODE: EnvMode = location.includes('talktomeinkorean.kr') ? 'dev' : 'prod' as EnvMode;

export const REGION = DEPLOY_MODE === 'prod' ? 'us-east-1' : 'ap-northeast-1';
export const APP_PROTOCOL =  'https';
export const SITE_ROOT = DEPLOY_MODE === 'prod' ? 'talktomeinkorean.com' : 'talktomeinkorean.kr';

export const REST_ENDPOINT = `${APP_PROTOCOL}://${SITE_ROOT}/wp-json/TTMIKRestAuth/v1`;
export const OAUTH_ENDPOINT = `${APP_PROTOCOL}://${SITE_ROOT}/wp-json/TTMIKOAuth/v1`;

// admin@talktomeinkorean
// https://console.cloud.google.com/apis/credentials?project=social-glogin-257009&supportedpurview=project
// Social GLogin
export const GOOGLE_CLIENT_ID = '899288362337-723hg5o6vqgha34qbsbu2g33ls7s0eej.apps.googleusercontent.com';

export const FACEBOOK_APP_ID = '700198083824435';

// 아래 값들은 실서버 토큰이라 주의할것
export const APPLE_CLIENT_ID = 'com.talktomeinkorean.nsclient';

// 사이트키 https://www.google.com/recaptcha/admin/site/632411569
export const RECAPTCHA_V3_SITE_KEY = '6Lex1bElAAAAAHI_gIHsefM6tYIg4-Dle-5DBkqh';
