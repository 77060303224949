import axios, { AxiosResponse } from 'axios';
import { useCallback, useRef, useState } from 'react';
import { useStore } from 'zustand';
import { ModalAction } from '../../../components/organisms/Modal';
import { OAUTH_ENDPOINT } from '../../../config';
import { afterLoginRedirectUrl } from '../../../lib/constants';
import { ErrorContainer } from '../../container/Error';
import { LoadingContainer } from '../../container/Loading';
import { AuthProvider, CheckAgreementResponse, ProviderAuthToken } from '../../interface/auth/Auth';
import { AppleLoginImpl } from '../Apple';
import { FacebookLoginImpl } from '../Facebook';
import { googleLoginImpl } from '../Google';
import axiosRequestConfig from '../xhr';

const socialLoginImplRecord:Record<AuthProvider, ()=> Promise<ProviderAuthToken<AuthProvider>>> = {
  google: googleLoginImpl,
  facebook: FacebookLoginImpl,
  apple: AppleLoginImpl
};

const useSocialLoginAction = () => {
  const flagLoading = useStore(
    LoadingContainer,
    (container) => container.flagLoading,
  );

  const [errorResponse, handleError] = useStore(
    ErrorContainer,
    (container) => [container.errorResponse, container.handleError],
  );

  const [authToken, setAuthToken] = useState<ProviderAuthToken<AuthProvider> | null>(null);

  const modalRef = useRef<ModalAction>(null);

  const openModal = useCallback(async () => {
    const modalAction = modalRef.current;
    if (modalAction) {
      modalAction.open();
    }
  }, []);

  const closeModal = useCallback(async () => {
    const modalAction = modalRef.current;
    if (modalAction) {
      modalAction.close();
    }
  }, []);

  /**
   * 로그인 요청
   * @param data ProviderAuthToken<AuthProvider>
   * @param isNewsletter boolean
   * @returns Promise<void>
   */
  const requestLogin = useCallback(async (data : ProviderAuthToken<AuthProvider>, isNewsletter : boolean = false) => {
    flagLoading(true);
    const token = JSON.stringify(data);
    try {
      await axios.post<{}, AxiosResponse>(`${OAUTH_ENDPOINT}/${data.type}`, {
        referer: 'talktomeinkorean',
        platform: 'ttmik-web',
        access_token: token,
        isNewsletter
      }, axiosRequestConfig);
      window.location.replace(afterLoginRedirectUrl);
    } catch (error : unknown) {
      flagLoading(false);
      handleError(error);
    }
  }, [flagLoading, handleError]);

  /**
   * 약관 동의 여부 체크
   * @param data ProviderAuthToken<AuthProvider>
   * @returns Promise<void>
   */
  const requestCheckAgreement = useCallback(async (data : ProviderAuthToken<AuthProvider>) => {
    flagLoading(true);
    const token = JSON.stringify(data);
    await axios.post<{}, AxiosResponse<CheckAgreementResponse>>(`${OAUTH_ENDPOINT}/check-agreement`, { provider: data.type, access_token: token, referer: 'talktomeinkorean' }, axiosRequestConfig).then((res) => {
      if (res.data.needAgree) {
        // 약관 동의가 필요하면 모달창을 띄움
        flagLoading(false);
        openModal();
      } else {
        // 약관 동의가 필요없으면 바로 로그인 요청
        requestLogin(data);
      }
    }).catch((error) => {
      flagLoading(false);
      handleError(error);
    });
  }, [flagLoading, handleError, openModal, requestLogin]);
  /**
   * 약관 동의 여부 전송
   * @param data RestPolicyInput
   * @returns Promise<void>
   */
  const approveCheckAgreement = useCallback(async (data : ProviderAuthToken<AuthProvider>, isNewsletter : boolean) => {
    flagLoading(true);
    try {
      const token = JSON.stringify(data);
      const payload = {
        provider: data.type, access_token: token, referer: 'talktomeinkorean', isNewsletter
      };
      await axios.post<{}, AxiosResponse<CheckAgreementResponse>>(`${OAUTH_ENDPOINT}/approve-agreement`, payload, axiosRequestConfig);
      closeModal();
      await requestLogin(data);
    } catch (error : unknown) {
      flagLoading(false);
      handleError(error);
    } finally {
      closeModal();
      flagLoading(false);
    }
  }, [closeModal, flagLoading, handleError, requestLogin]);

  /**
   * 로그인 요청
   * @param providerType AuthProvider
   * @returns Promise<void>
   */
  const onLogin = useCallback(async (providerType: AuthProvider) => {
    const impl = socialLoginImplRecord[providerType];
    if (!impl) throw new Error('not supported provider');
    try {
      const data = await impl();
      setAuthToken(data);
      await requestCheckAgreement(data);
    } catch (error : unknown) {
      flagLoading(false);
      handleError(error);
    }
  }, [flagLoading, handleError, requestCheckAgreement]);

  return {
    requestLogin,
    requestCheckAgreement,
    onLogin,
    authToken,
    setAuthToken,
    errorResponse,
    modalRef,
    openModal,
    closeModal,
    approveCheckAgreement
  };
};

export default useSocialLoginAction;
