import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import {
  LightPurpleColor, primaryColor, UiColorList, white
} from '../../../core/ColorList';
import { ColorViewStyledProps } from '../../base/Core';

export type ButtonBaseProps = {
  backgroundColor?: UiColorList;
  rounded?: number;
};
 type ButtonProps = ButtonBaseProps & {
   style?: React.CSSProperties;
   onClick?: () => void,
   disabled?: boolean;
 }  & JSX.IntrinsicElements['button'];

const ButtonTypoStyled = styled.span<
Partial<ColorViewStyledProps>
>`
     text-align: center;
     font-size: 14px;
      font-weight: 600;
     font-family: 'DM Sans';
 `;
const ButtonViewStyled = styled.button<ButtonBaseProps>`
     background-color: ${(props) => props.backgroundColor};
     width: 100%;
     padding : 14px 27px;
     border-radius: ${(props) => props.rounded ?? 0}px;
     color: ${(props) => props?.color ?? white};
     border : 0;
     cursor: pointer;
 `;
const CircleButtonViewStyled = styled.button<{
  borderColor: UiColorList;
}>`
     border: 1px solid ${(props) => props.borderColor};
     border-radius: 100%;
     width: 72px;
     height: 72px;
     padding: 12px;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
 `;

export function Button({
  backgroundColor = primaryColor,
  color  = white,
  children,
  onClick,
  rounded,
  style,
  disabled,
}: PropsWithChildren<ButtonProps>) {
  return (
    <ButtonViewStyled
      backgroundColor={disabled ? LightPurpleColor : backgroundColor}
      onClick={onClick}
      rounded={rounded}
      style={style}
      disabled={disabled}
      color={color}
    >
      <ButtonTypoStyled>{children}</ButtonTypoStyled>
    </ButtonViewStyled>
  );
}
export type CircleButtonProps = PropsWithChildren<ButtonProps & { borderColor: UiColorList }>;
export function CircleButton({
  borderColor,
  children,
  onClick,
  style,
}: CircleButtonProps) {
  return (
    <CircleButtonViewStyled
      borderColor={borderColor}
      onClick={onClick}
      style={style}
    >
      <ButtonTypoStyled>{children}</ButtonTypoStyled>
    </CircleButtonViewStyled>
  );
}
