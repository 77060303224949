import VerifiedEmailExpiredTemplate from '../components/templates/VerifiedEmailExpiredTemplate';

// 이메일 인증 링크 클릭 시 이동하는 페이지
function VerifiedEmailExpiredPage() {
  return (
    <VerifiedEmailExpiredTemplate  />
  );
}

export default VerifiedEmailExpiredPage;
