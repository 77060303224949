import styled from '@emotion/styled';
import SpinnerIndicatorSvg from '../icon/SpinnerIndicator';

type SpinnerType = {
  size: number;
  color: string;
};

const ActivityIndicatorBackground = styled.div`
    position: fixed;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  `;
const ActivityIndicatorWrapper = styled.div`
    display: flex;
    position: fixed;
    z-index: 999;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    animation: rotate 4s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);  
    }
  }
`;

export function Spinner({ size, color }: SpinnerType) {
  return (
    <ActivityIndicatorWrapper>
      <SpinnerIndicatorSvg width={size} height={size} fill={color} />
    </ActivityIndicatorWrapper>
  );
}

export function SpinnerBg({ size, color }: SpinnerType) {
  return (
    <ActivityIndicatorBackground>
      <ActivityIndicatorWrapper>
        <SpinnerIndicatorSvg width={size} height={size} fill={color} />
      </ActivityIndicatorWrapper>
    </ActivityIndicatorBackground>
  );
}
