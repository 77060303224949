import styled from '@emotion/styled';

const SvgWrapperView = styled.div`
    align-content:center;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const SvgWrapperButton = styled.button`
    background: none;
    border : 0;
    outline : none;
    cursor : pointer;
`;

export type PickSvgComponentProps = Partial<
Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill' | 'color'>
> & {
  containerStyle?: React.CSSProperties
};
type TouchableSvgComponentProps = PickSvgComponentProps & {
  onClick?: () => void;
  disabled?: boolean;
};

export type SvgComponent = (props: PickSvgComponentProps) => JSX.Element;

export const svgWrapperHOC = (Component: SvgComponent) => (
  function SvgInner({ containerStyle, ...props }: PickSvgComponentProps) {
    return (
      <SvgWrapperView style={containerStyle}>
        <Component {...props} />
      </SvgWrapperView>
    );
  }
);
export const svgWrapperTouchableHOC = (Component: SvgComponent) => (
  function SvgInnerTouchable({
    onClick, disabled, containerStyle, ...props
  }: TouchableSvgComponentProps) {
    return (
      <SvgWrapperButton type="button" onClick={onClick} disabled={disabled} style={containerStyle}>
        <Component {...props} />
      </SvgWrapperButton>
    );
  }
);
