import {
  boolean, object, ObjectSchema, string
} from 'yup';
import { RefererArray, RestPolicyInput, RestRegisterInput } from '../hooks/interface/auth/Rest';
import castFormTag from '../utils/FormTag';

export const RegisterPolicyForm = castFormTag<RestRegisterInput>();

const registerPolicySchema:ObjectSchema<RestPolicyInput> = object({
  isAgreePolicy: boolean().required().oneOf([true], ''),
  isAdult: boolean().required().oneOf([true], ''),
  isTermsAndConditions: boolean().required().oneOf([true], ''),
  isNewsletter: boolean().default(false),
  referer: string().oneOf(RefererArray).required(),
}).strict(true);

export default registerPolicySchema;
