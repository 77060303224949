import styled from '@emotion/styled';
import { Modal as ReactModal } from '@restart/ui';
import { RenderModalBackdropProps } from '@restart/ui/esm/Modal';
import {
  forwardRef, useCallback, useImperativeHandle, useRef,
  useState
} from 'react';
import { useHover } from 'usehooks-ts';
import { grayDark, standardBlack } from '../../core/ColorList';
import { ContainerInnerBox } from '../base/Layout';
import CloseSvg from '../icon/Close';

export const noop = () => { };
export const asyncNoop = async () => { };

export type ModalProps = {
  // STUB!
  children : React.ReactNode
};
export type ModalAction = {
  open: () => void;
  close: () => void;
};

const CloseModalBtn = styled.button`
  right:0;
  top: 20px;
  width : auto;
  position: absolute;
  right: 20px;
  top: 20px;
`;

const Modal = forwardRef<ModalAction, ModalProps>((props, ref) => {
  const modalRef = useRef(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: showModal,
      close: closeModal,
    }),
    [closeModal, showModal],
  );

  return (
    <ReactModal
      show={isModalVisible}
      onHide={closeModal}
      ref={modalRef}
      renderBackdrop={(modalProps:RenderModalBackdropProps) => (
        <div
          {...modalProps}
          style={{
            zIndex: 100,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        />
      )}
      style={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 102,
        backgroundColor: 'white',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px'
      }}
      ariaHideApp={false}
    >
      <ContainerInnerBox>
        <CloseModalBtn
          onClick={closeModal}
          ref={hoverRef}
        >
          <CloseSvg color={isHover ? standardBlack : grayDark}  />
        </CloseModalBtn>
        {props.children}
      </ContainerInnerBox>
    </ReactModal>
  );
});

export default Modal;
