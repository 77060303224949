export const RefererArray = ['talktomeinkorean', 'ttmik-stories'];
export type Referer = typeof RefererArray[number];

export type RestLoginInput = {
  email: string
  password: string
  remember: boolean;
  referer : Referer;
};

export type RestPolicyInput = {
  isAgreePolicy: boolean;
  isAdult: boolean;
  isTermsAndConditions: boolean;
  isNewsletter: boolean;
  referer: Referer;
};

export type RestRegisterInfoInput = {
  email: string
  password: string
  passwordConfirm: string;
  username: string;
};

export type RestRegisterInput = RestPolicyInput & RestRegisterInfoInput;

export const defaultLoginValues = {} as RestLoginInput;
export const defaultRegisterValues = {} as RestRegisterInput;
export const defaultRegisterInfoValues = {} as RestRegisterInfoInput;
export const defaultRegisterPolicyValues = {} as RestPolicyInput;

export const restRegisterInfoInputKeys :(keyof RestRegisterInfoInput)[] = ['email', 'password', 'passwordConfirm', 'username'];
export const RestPolicyInputKeys :(keyof RestPolicyInput)[] = ['isAdult', 'isAgreePolicy', 'isNewsletter', 'isTermsAndConditions'];

export type VerifyEmailInput = {
  auth?: string | null;
  email: string;
};
