import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'zustand';
import { APP_PROTOCOL, SITE_ROOT } from '../../config';
import { primaryColor, white } from '../../core/ColorList';
import useLoginAction from '../../hooks/action/use/useLoginAction';
import { LoadingContainer } from '../../hooks/container/Loading';
import { RestLoginInput, RestPolicyInput, defaultLoginValues } from '../../hooks/interface/auth/Rest';
import loginSchema, { LoginForm } from '../../validator/UserLogin';
import { Button } from '../atoms/button/Button';
import {
  BannerContainerWrapperBox,
  ContainerInnerBox,
  FlexCol, FlexRow, FlexRowAlignCenterBox, SignNotice, SpacingLarge, SpacingMedium, SpacingSmall
} from '../base/Layout';
import {
  DmSans14Bold,
  DmSans14Medium, ErrorText, LinkText,
  Pretandard14Bold,
  Pretandard14Regular
} from '../base/Typo';
import EmailSvg from '../icon/Email';
import InfoSvg from '../icon/Info';
import PasswordSvg from '../icon/Password';
import LeftSideBanner from '../molecules/LeftSideBanner';
import HeaderTTMIKLogo from '../molecules/Logo';
import { LoadingSpinnerBg } from '../organisms/LoadingSpinner';
import Modal from '../organisms/Modal';
import SocialLoginOptions from '../organisms/SocialLogin';
import TermsService from '../organisms/TermsService';

const LoginInfoTextArea = styled.div`
  margin-left: 6px;
`;

function LoginTemplate() {
  const {
    register, handleSubmit, watch, formState: {
      isValid, errors,
    }
  } = useForm<RestLoginInput>({
    resolver: yupResolver(loginSchema),
    mode: 'onSubmit',
    defaultValues: defaultLoginValues,
  });

  const [isLoading, flagLoading] = useStore(
    LoadingContainer,
    (container) => [container.isLoading, container.flagLoading],
  );

  const {
    onLogin, errorResponse, modalRef, approveCheckAgreement, loginInfo
  } = useLoginAction();

  /**
   * 약관 동의 후 로그인 요청
   * @param data RestPolicyInput
   * @returns void
   */
  const onAgree = useCallback(async (data: RestPolicyInput) => {
    flagLoading(true);
    if (loginInfo) {
      await approveCheckAgreement(loginInfo, data.isNewsletter);
      flagLoading(false);
    }
  }, [approveCheckAgreement, flagLoading, loginInfo]);

  return (
    <>
      {isLoading ? <LoadingSpinnerBg /> : null}
      <FlexRow flex={1}>
        <LeftSideBanner />
        <BannerContainerWrapperBox>
          <ContainerInnerBox>
            <HeaderTTMIKLogo />
            <SignNotice style={{ textAlign: 'center', marginTop: 50 }}>
              <DmSans14Bold>
                We kindly request all members to verify their email addresses to continue using our website.
              </DmSans14Bold>
              <DmSans14Medium>
                This verification process enhances our website&apos;s security and enables us to communicate with you more effectively.
              </DmSans14Medium>

            </SignNotice>
            <form onSubmit={handleSubmit(onLogin)} name="loginForm">
              <SpacingSmall>
                <LoginForm.LabelInput
                  icon={EmailSvg}
                  name="email"
                  title="Email"
                  register={register('email')}
                  errors={errors.email}
                />
              </SpacingSmall>
              <SpacingSmall>
                <LoginForm.LabelInput
                  icon={PasswordSvg}
                  name="password"
                  secure
                  title="Password"
                  register={register('password')}
                  errors={errors.password}
                />
              </SpacingSmall>
              <LoginForm.HiddenInput
                name="referer"
                title="referer"
                register={register('referer')}
                value="talktomeinkorean"
                errors={errors.referer}
              />
              <SpacingMedium>
                <FlexRow flex={1}>
                  <LoginForm.Checkbox
                    name="remember"
                    title="remember"
                    register={register('remember')}
                    checked={watch('remember')}
                    errors={errors.remember}
                  >
                    <DmSans14Medium>
                      Keep me signed in
                    </DmSans14Medium>
                  </LoginForm.Checkbox>
                  <DmSans14Medium>
                    <LinkText to={`${APP_PROTOCOL}://${SITE_ROOT}/my-account/lost-password/`}>
                      Forgot your password?
                    </LinkText>
                  </DmSans14Medium>
                </FlexRow>
              </SpacingMedium>
              <SpacingMedium>
                {errorResponse && (<ErrorText>{errorResponse}</ErrorText>)}
              </SpacingMedium>
              <SpacingLarge>
                <Button
                  type="submit"
                  disabled={!isValid}
                  backgroundColor={primaryColor}
                  color={white}
                  rounded={10}
                >
                  Sign In
                </Button>
              </SpacingLarge>
            </form>
            <SpacingLarge>
              <FlexRowAlignCenterBox flex={1}>
                <DmSans14Medium>or Sign in with </DmSans14Medium>
                <SocialLoginOptions />
              </FlexRowAlignCenterBox>
            </SpacingLarge>
            <SpacingLarge>
              <FlexRow flex={1}>
                <InfoSvg />
                <LoginInfoTextArea>
                  <Pretandard14Bold>
                    Facebook login is temporarily unavailable.
                  </Pretandard14Bold>
                  <Pretandard14Regular>
                    Please check out
                    {' '}
                    <LinkText to="https://talktomeinkorean.freshdesk.com/support/solutions/articles/48000590511-why-can-t-i-sign-in-" target="_blank" style={{ textDecoration: 'underline' }}>
                      this FAQ
                    </LinkText>
                    {' '}
                    to find alternative solutions.
                  </Pretandard14Regular>
                </LoginInfoTextArea>
              </FlexRow>
            </SpacingLarge>
          </ContainerInnerBox>
        </BannerContainerWrapperBox>
        <Modal ref={modalRef}>
          <FlexCol>
            {/* <Pretandard14Bold>
              Sign in with your account
            </Pretandard14Bold>
            <SpacingSmall>
              <NewSiteMigrationBanner />
              <Pretandard12Medium>
                If you sign up on our website, you can use the same sign-in information on our TTMIK: Audio app.
              </Pretandard12Medium>
            </SpacingSmall> */}
            <TermsService onSubmit={onAgree} scope="login" />
          </FlexCol>
        </Modal>
      </FlexRow>
    </>
  );
}

export default LoginTemplate;
