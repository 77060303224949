import axios from 'axios';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'zustand';
import { REST_ENDPOINT } from '../../../config';
import { ErrorContainer } from '../../container/Error';
import { LoadingContainer } from '../../container/Loading';
import { UserInfoContainer } from '../../container/User';
import { VerifyEmailInput } from '../../interface/auth/Rest';

const useVertifyEmailAction = () => {
  const navigate = useNavigate();

  const setEmail = useStore(
    UserInfoContainer,
    (container) => container.setEmail,
  );

  const flagLoading = useStore(
    LoadingContainer,
    (container) => container.flagLoading,
  );

  const [errorResponse, handleError, resetError] = useStore(
    ErrorContainer,
    (container) => [container.errorResponse, container.handleError, container.resetError],
  );
    // Get 'auth' from url query string
  const params = useParams();

  /**
   * 회원가입 요청
   * @param data RestRegisterInput
   * @returns Promise<void>
   */

  const onVertify = useCallback(async (data : VerifyEmailInput) => {
    try {
      flagLoading(true);

      if (!params?.auth) throw new Error('No Auth Code');

      const paylaod = {
        email: data.email,
        authCode: params?.auth,
      };

      // eslint-disable-next-line no-alert
      const flag = window.confirm(`Do you want us to send a verification email to ${data.email}?`);
      if (flag) {
        await axios.post(`${REST_ENDPOINT}/migration-email-send`, paylaod);
        // set email with Zustand
        setEmail(data.email);
        resetError();
        // redirection to
        navigate('/confirm');
      }
    } catch (error: unknown) {
      flagLoading(false);
      handleError(error);
    } finally {
      flagLoading(false);
    }
  }, [flagLoading, handleError, navigate, params?.auth, resetError, setEmail]);

  return {
    onVertify,
    errorResponse,
  };
};

export default useVertifyEmailAction;
