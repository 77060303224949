import { standardBlack } from '../../core/ColorList';

function CloseEyesSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 35} height={props.height ?? 10} viewBox="0 0 35 10" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4967 8.97917V5.02371M17.4967 5.02371C20.4432 5.02371 23.0824 3.46692 24.8463 1.02148M17.4967 5.02371C14.5501 5.02371 11.9109 3.46692 10.147 1.02148M13.0668 3.74754L10 6.81436M21.9265 3.74754L25 6.81436" stroke={props.color ?? standardBlack} strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  );
}

export default CloseEyesSvg;
