import styled from '@emotion/styled';
import {
  UiColorList, errorColor, standardBlack, white
} from '../../../core/ColorList';
import { FormCheckboxProps } from '../../../utils/FormUtils';
import { ErrorText } from '../../base/Typo';
import CheckSvg from '../../icon/Check';
import { InputFlexDisplay } from './HiddenInput';

const checkboxWidth = 18;
const checkboxHeight = 18;
const CheckboxLabel = styled.label<{ borderColor : UiColorList }>`
  position: relative;
  display: flex;
  align-items: flex-start; 
  user-select: none;
  cursor: default;
  &:before {
    display: block;
    content: "";
    width: ${checkboxWidth}px;
    min-width : ${checkboxWidth}px;
    height: ${checkboxHeight}px;
    border: 1px solid ${(props) => props.borderColor};
    border-radius: 6px;
    background-color: ${white};
  }

  &:after {
    position: absolute;
    top: 9px;
    left: 0;
    transform: translateY(-50%);
    display: block;
    opacity: 0;
    content: "";
    width: ${checkboxWidth}px; 
    height: ${checkboxHeight}px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: ${standardBlack};
  }

`;

const CheckboxInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  width: 1px;

  &:checked + label {
    :after {
      opacity: 1;
    }
  }
`;

const CheckboxIconArea = styled.div`
  position: absolute;
  z-index: 1;
  width: ${checkboxWidth}px;
  height: ${checkboxHeight + 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckboxLabelTitleArea = styled.div`
  margin-left: 10px;
`;

function FormCheckbox<Data>({
  name,
  title,
  register,
  children,
  checked = false,
  scope,
  errors,
  ...props
}: FormCheckboxProps<Data>) {
  const inputName = scope ? scope + name : name;
  return (
    <InputFlexDisplay>
      <CheckboxInput
        type="checkbox"
        placeholder={title}
        id={inputName}
        checked={checked}
        onChange={props.onChange}
        {...register}
      />
      <CheckboxLabel htmlFor={inputName} borderColor={errors ? errorColor : standardBlack}>
        <CheckboxIconArea>
          <CheckSvg color={white} width={10} height={8} />
        </CheckboxIconArea>
        <CheckboxLabelTitleArea>{children}</CheckboxLabelTitleArea>
      </CheckboxLabel>
      {!checked && errors && <ErrorText>{errors.message}</ErrorText>}
    </InputFlexDisplay>
  );
}

export default FormCheckbox;
