import { OAUTH_ENDPOINT } from '../../config';
import { ProviderAuthToken } from '../interface/auth/Auth';

/**
 * apple developer
 * https://developer.apple.com/account/resources/identifiers/list
 *  https://developer.apple.com/account/resources/identifiers/list/serviceId
 * https://developer.apple.com/account/resources/identifiers/serviceId/edit/Y7345U9DF6
 */

export type AppleAuthToken = ProviderAuthToken<'apple'>;

export const AppleLoginImpl = async (): Promise<AppleAuthToken> => {
  window.open(
    `${OAUTH_ENDPOINT}/request-url/apple`,
    'test',
    'width=600,height=600,left=0,top=0,scrollbars=0',
  );
  return new Promise((resolve, reject) => {
    window.addEventListener('message', (event) => {
      if (event.data) {
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data);
          const payload :  AppleAuthToken = {
            type: 'apple',
            ...data,
          };
          resolve(payload);
        }
      } else {
        reject(new Error('Apple Login Error!'));
      }
    });
  });
};
export default AppleLoginImpl;
