import RegisterCompleteTemplate from '../components/templates/RegisterCompleteTemplate';

// 이메일 발송 완료
function RegisterCompletePage() {
  return (
    <RegisterCompleteTemplate />
  );
}

export default RegisterCompletePage;
