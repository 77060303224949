function FacebookLogoSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 39} height={props.height ?? 39} viewBox="0 0 39 39" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="39" height="39" rx="8" />
      <path d="M28.5 19.5518C28.5 14.8576 24.6941 11.0518 20 11.0518C15.3059 11.0518 11.5 14.8576 11.5 19.5518C11.5 23.7947 14.6082 27.3108 18.6719 27.9483V22.009H16.5136V19.551H18.6719V17.6796C18.6719 15.5497 19.9412 14.3724 21.8827 14.3724C22.8121 14.3724 23.7853 14.5389 23.7853 14.5389V16.6306H22.7129C21.6568 16.6306 21.3274 17.2858 21.3274 17.958V19.5518H23.6847L23.3079 22.0097H21.3274V27.949C25.3918 27.3108 28.5 23.794 28.5 19.5518Z" fill="white" />
    </svg>
  );
}

export default FacebookLogoSvg;
