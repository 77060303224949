import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { APP_PROTOCOL, SITE_ROOT } from '../../config';
import { standardBlack } from '../../core/ColorList';
import { Referer, RestPolicyInput, RestPolicyInputKeys } from '../../hooks/interface/auth/Rest';
import { CheckboxRegisterScope } from '../../utils/FormUtils';
import { RegisterForm } from '../../validator/UserRegister';
import Checkbox from '../atoms/Input/Checkbox';
import { Line, SpacingMedium } from '../base/Layout';
import {
  DmSans14Medium, LinkLineText, Pretandard12Medium, TypoColorStyled
} from '../base/Typo';

type TermsServiceContentProps = {
  scope : CheckboxRegisterScope
};
function TermsServiceContent({ scope } : TermsServiceContentProps) {
  const methods = useFormContext<RestPolicyInput>();
  const {
    register,  watch, setValue, formState: { errors }
  } = methods;

  const isSelectAll = RestPolicyInputKeys.every((key) => watch(key) === true);

  const onSelectAll = useCallback(() => {
    RestPolicyInputKeys.forEach((field) => setValue(field, !isSelectAll));
  }, [isSelectAll, setValue]);

  const refererTalkToMeInKorean :Referer = 'talktomeinkorean';

  return (
    <div>
      <SpacingMedium>
        <Checkbox
          name="isSelectAll"
          checked={isSelectAll}
          onChange={onSelectAll}
          errors={undefined}
          scope={scope}
        >
          <DmSans14Medium>Accept All</DmSans14Medium>
        </Checkbox>
      </SpacingMedium>
      <SpacingMedium>
        <Line borderColor={standardBlack} />
      </SpacingMedium>

      <SpacingMedium>
        <RegisterForm.Checkbox
          name="isAgreePolicy"
          register={register('isAgreePolicy')}
          checked={isSelectAll || watch('isAgreePolicy')}
          errors={errors.isAgreePolicy}
          scope={scope}
        >
          <Pretandard12Medium>
            Consent to
            {' '}
            <LinkLineText to={`${APP_PROTOCOL}://${SITE_ROOT}/terms-and-conditions-2023/`} target="_blank">
              Terms and Conditions
            </LinkLineText>
            {' '}
            <TypoColorStyled>(required)</TypoColorStyled>
          </Pretandard12Medium>
        </RegisterForm.Checkbox>
      </SpacingMedium>
      <SpacingMedium>
        <RegisterForm.Checkbox
          name="isTermsAndConditions"
          title="isTermsAndConditions"
          register={register('isTermsAndConditions')}
          checked={isSelectAll || watch('isTermsAndConditions')}
          errors={errors.isTermsAndConditions}
          scope={scope}
        >
          <Pretandard12Medium>
            Consent to
            {' '}
            <LinkLineText to={`${APP_PROTOCOL}://${SITE_ROOT}/privacy-policy-2023/`} target="_blank">
              Collection and Use of Personal Information
            </LinkLineText>
            {' '}
            <TypoColorStyled>(required)</TypoColorStyled>
          </Pretandard12Medium>
        </RegisterForm.Checkbox>
      </SpacingMedium>
      <SpacingMedium>
        <RegisterForm.Checkbox
          name="isAdult"
          title="isAdult"
          register={register('isAdult')}
          checked={isSelectAll || watch('isAdult')}
          errors={errors.isAdult}
          scope={scope}
        >
          <Pretandard12Medium>
            I am at least 14 years old.
            {' '}
            <TypoColorStyled>(required)</TypoColorStyled>
          </Pretandard12Medium>
        </RegisterForm.Checkbox>
      </SpacingMedium>
      <SpacingMedium>
        <RegisterForm.Checkbox
          name="isNewsletter"
          title="isNewsletter"
          register={register('isNewsletter')}
          checked={isSelectAll || watch('isNewsletter')}
          errors={errors.isNewsletter}
          style={{ alignItems: 'flex-start' }}
          scope={scope}
        >
          {' '}
          <Pretandard12Medium>
            I&#39;d like to receive newsletters, discount codes, and promotional information from Talk To Me In Korean via email. (optional)
          </Pretandard12Medium>
        </RegisterForm.Checkbox>
      </SpacingMedium>
      <SpacingMedium>
        <RegisterForm.HiddenInput
          name="referer"
          register={register('referer')}
          title="referer"
          errors={errors.referer}
          value={refererTalkToMeInKorean}
        />
      </SpacingMedium>
    </div>
  );
}

export default TermsServiceContent;
