import VerifyEmailTemplate from '../components/templates/VerifyEmailTemplate';

// 이메일 발송 완료
function VerifyEmailPage() {
  return (
    <VerifyEmailTemplate />
  );
}

export default VerifyEmailPage;
