import MigrateEmailTemplate from '../components/templates/MigrateEmailTemplate';

// 이메일 마이그레이션 페이지
function MigrateEmailPage() {
  return (
    <MigrateEmailTemplate />
  );
}

export default MigrateEmailPage;
