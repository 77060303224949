// color
export const primaryColor = '#3333FF';
export const selectedPrimaryBackgroundColor = 'rgba(96, 226, 255, 0.3)';

export const coreBlack = '#000';
export const charcoal = '#222';
export const standardBlack = '#333';
export const disabledBlack = '#c4c4c4';
export const grayDark = '#666';
export const standardGray = '#999';
export const grayItem = '#ccc';
export const grayLight = '#eee';
export const skyBlue = '#00A8E8';
export const lightPink = '#FFE2F7';
export const coolGray = '#F5F5FF';

export const white = '#fff';
export const transparent = 'transparent';
export const requiredColor = '#9C9CF7';
export const warningColor = '#FF9F38';
export const errorColor = '#FF604F';

export const LightPurpleColor = '#E0E0FF';

export type UiColorList =
| typeof coreBlack
| typeof primaryColor
| typeof grayItem
| typeof grayLight
| typeof grayDark
| typeof charcoal
| typeof standardBlack
| typeof disabledBlack
| typeof transparent
| typeof white
| typeof warningColor
| typeof errorColor
| typeof requiredColor
| typeof LightPurpleColor
| typeof skyBlue
| typeof lightPink;

// base layout
