import { standardBlack } from '../../core/ColorList';

function CloseSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 36} height={props.height ?? 37} viewBox="0 0 36 37" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24.3065L17.9995 18.3069M17.9995 18.3069L23.9991 12.3074M17.9995 18.3069L12.0009 12.3066M17.9995 18.3069L24 24.3057" stroke={props.color ?? standardBlack} strokeWidth="1.5" />
    </svg>
  );
}

export default CloseSvg;
