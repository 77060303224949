import { standardBlack } from '../../core/ColorList';

function LogoSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (

    <svg width={props.width ?? 140} height={props.height ?? 70} viewBox="0 0 140 70" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M35.0366 17.5H32.2927L27.125 30.625H30.0061L31.0579 27.8594H36.2256L37.2317 30.625H40.25L35.0366 17.5ZM35.3088 25.6094H31.8789L32.3362 24.25C32.7478 23.125 33.2051 21.8125 33.5252 20.8281H33.571C33.8481 21.5736 34.1776 22.5074 34.4801 23.3646C34.5771 23.6392 34.6712 23.906 34.76 24.1562L35.3088 25.6094Z" fill={props.fill ?? standardBlack}  />
      <path d="M42 17.5H44.9484V28.0938H50.75V30.625H42V17.5Z" fill={props.fill ?? standardBlack}  />
      <path d="M52.5 17.5H55.4023V23.3125H55.4491L60.0365 17.5H63.4537L58.4918 23.5938L63.875 30.625H60.3174L55.4491 24.0625H55.4023V30.625H52.5V17.5Z" fill={props.fill ?? standardBlack}  />
      <path fillRule="evenodd" clipRule="evenodd" d="M84.875 17.5C80.8955 17.5 77.875 20.3255 77.875 24.0625C77.875 27.7995 80.8955 30.625 84.875 30.625C88.8545 30.625 91.875 27.7539 91.875 24.0625C91.875 20.3711 88.8545 17.5 84.875 17.5ZM84.8761 20.0065C87.2254 20.0065 88.9515 21.7839 88.9515 24.0625C88.9515 26.2956 87.2254 28.1185 84.8761 28.1185C82.5268 28.1185 80.8008 26.3411 80.8008 24.0625C80.8008 21.7839 82.5268 20.0065 84.8761 20.0065Z" fill={props.fill ?? standardBlack}  />
      <path d="M112.875 17.5H120.529V20.0222H115.618V22.5912H119.865V24.9733H115.618V28.1028H120.75V30.625H112.919V17.5H112.875Z" fill={props.fill ?? standardBlack}  />
      <path d="M21.7598 20.0312H17.5V17.5H28.875V20.0312H24.6152V30.625H21.713V20.0312H21.7598Z" fill={props.fill ?? standardBlack}  />
      <path d="M70.7598 20.0312H66.5V17.5H77.875V20.0312H73.662V30.625H70.7598V20.0312Z" fill={props.fill ?? standardBlack}  />
      <path d="M101.763 24.9062C101.114 23.9688 100.464 23.0312 99.8613 22.0938H99.8149C99.8149 23.0312 99.8613 24.25 99.8613 25.375V30.625H97.125V17.5H99.8613L103.711 23.0312L107.56 17.5H110.25V30.625H107.375V25.375C107.375 24.2969 107.375 23.0312 107.421 22.0938H107.375C106.772 23.0312 106.122 23.9688 105.473 24.9062L103.572 27.625L101.763 24.9062Z" fill={props.fill ?? standardBlack}  />
      <path d="M30.7766 47.2031C29.9085 46.0781 29.0886 44.9531 28.2205 43.8281H28.1722C28.1722 45.0938 28.2205 46.3594 28.2205 47.625V52.5H25.375V39.375H28.1722L32.2234 44.6719C33.0915 45.7969 33.9114 46.9219 34.7795 48.0469H34.8278C34.8278 46.7812 34.7795 45.5156 34.7795 44.25V39.375H37.625V52.5H34.8278L30.7766 47.2031Z" fill={props.fill ?? standardBlack}  />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.875 39.375C59.8955 39.375 56.875 42.2005 56.875 45.9375C56.875 49.6745 59.8955 52.5 63.875 52.5C67.8545 52.5 70.875 49.6289 70.875 45.9375C70.875 42.2461 67.8545 39.375 63.875 39.375ZM63.8761 41.8815C66.2254 41.8815 67.9515 43.6589 67.9515 45.9375C67.9515 48.2161 66.2254 49.9935 63.8761 49.9935C61.5268 49.9935 59.8008 48.2161 59.8008 45.9375C59.8008 43.6589 61.5748 41.8815 63.8761 41.8815Z" fill={props.fill ?? standardBlack}  />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.625 39.375H72.625V52.5H75.4432V47.2969H76.6705L79.8977 52.5H83.125L79.5341 46.9688C81.2159 46.4062 82.125 45.0469 82.125 43.3594C82.125 41.8594 81.5341 40.875 80.5795 40.2656C79.5795 39.6094 78.125 39.375 76.625 39.375ZM78.6232 44.8125C78.1687 45.0468 77.6232 45.1406 76.8505 45.1406V45.1875H75.4414V41.9062H76.5323C77.4869 41.9062 78.1687 42 78.6232 42.2343C79.1232 42.4687 79.3505 42.8906 79.3505 43.5C79.3505 44.1093 79.0778 44.5781 78.6232 44.8125Z" fill={props.fill ?? standardBlack}  />
      <path d="M84.875 39.375H92.5288V41.8972H87.618V44.4662H91.8652V46.8483H87.618V49.9778H92.75V52.5H84.9192V39.375H84.875Z" fill={props.fill ?? standardBlack}  />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.412 39.375H99.6677L94.5 52.5H97.3811L98.4329 49.7344H103.601L104.607 52.5H107.625L102.412 39.375ZM102.735 47.4844H99.3047L99.762 46.125C100.174 45 100.631 43.6875 100.951 42.7031H100.997C101.274 43.4486 101.603 44.3824 101.906 45.2396C102.003 45.5142 102.097 45.781 102.186 46.0312L102.735 47.4844Z" fill={props.fill ?? standardBlack}  />
      <path d="M114.777 47.2031C113.908 46.0781 113.089 44.9531 112.22 43.8281H112.172C112.172 45.0938 112.22 46.3594 112.22 47.625V52.5H109.375V39.375H112.172L116.223 44.6719C117.092 45.7969 117.911 46.9219 118.78 48.0469H118.828C118.828 46.7812 118.78 45.5156 118.78 44.25V39.375H121.625V52.5H118.828L114.777 47.2031Z" fill={props.fill ?? standardBlack}  />
      <path d="M20.125 39.375H22.75V52.5H20.125V39.375Z" fill={props.fill ?? standardBlack}  />
      <path d="M44.625 39.375H47.5393V45.1875H47.5863L52.1457 39.375H55.577L50.5945 45.4688L56 52.5H52.4277L47.5393 45.9375H47.4923V52.5H44.625V39.375Z" fill={props.fill ?? standardBlack}  />
      <path d="M120.75 49.875H140V52.5H120.75V49.875Z" fill={props.fill ?? standardBlack}  />
      <path d="M0 28H22.75V30.625H0V28Z" fill={props.fill ?? standardBlack}  />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H140V70H0V0ZM137.205 67.2001V2.80005H2.80469V67.2001H137.205Z" fill={props.fill ?? standardBlack}  />
    </svg>
  );
}

export default LogoSvg;
