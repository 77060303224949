import { FieldValues } from 'react-hook-form';
import FormCheckbox from '../components/atoms/Input/Checkbox';
import FormHiddenInput from '../components/atoms/Input/HiddenInput';
import FormInput from '../components/atoms/Input/Input';
import FormLabelInput from '../components/atoms/Input/LabelInput';

import { FormCheckboxProps, FormInputIconProps, FormInputProps } from './FormUtils';

export default function castFormTag<Data extends FieldValues>() {
  return {
    Input(props:FormInputIconProps<Data>) {
      return (
        <FormInput<Data> {...props} />
      );
    },
    LabelInput(props:FormInputIconProps<Data>) {
      return (
        <FormLabelInput<Data> {...props} />
      );
    },
    HiddenInput(props:FormInputProps<Data>) {
      return (
        <FormHiddenInput<Data> {...props} />
      );
    },
    Checkbox(props:FormCheckboxProps<Data>) {
      return (
        <FormCheckbox<Data> {...props} />
      );
    },
  };
}
