import { useStore } from 'zustand';
import { coreBlack, grayLight, standardBlack } from '../../core/ColorList';
import { UserInfoContainer } from '../../hooks/container/User';
import {
  BannerContainerWrapperBox,
  CircleStyledList,
  ContainerInnerBox,
  FlexColAlignCenterBox, FlexColAlignStart, FlexRow, Spacing, SpacingLarge,
  SpacingSmall, TextCenteredBox
} from '../base/Layout';
import {
  LinkLineText, MediumParagraph, PillBadge, TitleSmall600
} from '../base/Typo';
import EmailSvg from '../icon/Email';
import LeftSideBanner from '../molecules/LeftSideBanner';
import HeaderTTMIKLogo from '../molecules/Logo';

// 이메일 발송 완료
function RegisterCompleteTemplate() {
  const email = useStore(
    UserInfoContainer,
    (container) => container.email,
  );

  return (
    <FlexRow flex={1}>
      <LeftSideBanner />
      <BannerContainerWrapperBox>
        <ContainerInnerBox>
          <FlexColAlignCenterBox>
            <HeaderTTMIKLogo />
            <TextCenteredBox>
              <Spacing top={48}>
                <TitleSmall600 color={standardBlack}>
                  Please verify your email address.
                </TitleSmall600>
                <SpacingLarge>
                  <MediumParagraph>
                    We have sent an email to
                    {email ? (
                      <SpacingSmall>
                        <PillBadge backgroundColor={grayLight} color={standardBlack}>
                          <EmailSvg />
                          {email}
                        </PillBadge>
                      </SpacingSmall>
                    ) :  null}
                  </MediumParagraph>
                  <SpacingLarge>
                    <MediumParagraph>
                      Please check your inbox and complete your sign-up process.
                    </MediumParagraph>
                  </SpacingLarge>
                </SpacingLarge>
              </Spacing>
            </TextCenteredBox>
            <SpacingLarge>
              <FlexColAlignStart>
                <CircleStyledList>
                  <li>
                    <span>The verification link will be valid up to 1 hour.</span>
                  </li>
                  <li>
                    <span>If you don&apos;t see any mail from us, please also check your spam box.</span>
                  </li>
                  <li>
                    <span>
                      If you have any issues with the email verification process, please
                      {' '}
                      <LinkLineText color={coreBlack} to="https://talktomeinkorean.freshdesk.com/support/tickets/new" target="_blank">contact us</LinkLineText>
                      .
                    </span>
                  </li>
                </CircleStyledList>
              </FlexColAlignStart>
            </SpacingLarge>
          </FlexColAlignCenterBox>
        </ContainerInnerBox>
      </BannerContainerWrapperBox>
    </FlexRow>
  );
}

export default RegisterCompleteTemplate;
