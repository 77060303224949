import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { primaryColor, white } from '../../core/ColorList';
import { RestPolicyInput, RestPolicyInputKeys, defaultRegisterPolicyValues } from '../../hooks/interface/auth/Rest';
import { CheckboxRegisterScope } from '../../utils/FormUtils';
import registerPolicySchema from '../../validator/UserRegisterPolicy';
import { Button } from '../atoms/button/Button';
import { SpacingLarge } from '../base/Layout';
import TermsServiceContent from '../molecules/TermsServiceContent';

type TermsServiceProps = {
  onSubmit: (data: RestPolicyInput) => void;
  scope : CheckboxRegisterScope;
};

function TermsService({ onSubmit, scope } : TermsServiceProps) {
  const methods  = useForm({
    resolver: yupResolver(registerPolicySchema),
    mode: 'onSubmit',
    defaultValues: defaultRegisterPolicyValues
  });
  const isSelectAll = RestPolicyInputKeys.every((key) => methods.watch(key) === true);
  const { handleSubmit, watch } = methods;

  const isValid = watch('isAdult') && watch('isAgreePolicy') && watch('isTermsAndConditions');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TermsServiceContent scope={scope}  />
        <SpacingLarge>
          <Button
            type="submit"
            disabled={!isValid && !isSelectAll}
            backgroundColor={primaryColor}
            color={white}
            rounded={10}
          >
            I Agree
          </Button>
        </SpacingLarge>
      </form>
    </FormProvider>
  );
}

export default TermsService;
