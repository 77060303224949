import styled from '@emotion/styled';
import { lightPink, standardBlack } from '../../core/ColorList';
import {
  desktop, laptop, mobileLandscapeWidth
} from '../base/Layout';
import { Jost12Regular } from '../base/Typo';

const LeftSideBannerWrapper = styled.div`
  background: ${lightPink};
  display : none;
  
  @media (min-width: ${laptop}){
    & {
      width: 450px;
      display : flex;
      flex-direction: column;
      flex-grow: 0;
      position: fixed;
      height: 100vh;
      z-index: 10;
    }
  }
  
  @media (min-width: ${desktop}}){
    & {
      width: 514px;
      display : flex;
      flex-direction: column;
    }
  }

`;

const LeftSideBannerContent = styled.div`
    @media (min-width: ${laptop}) {
      & {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 100%;
      }
    }
`;

const LeftSideBannerTitleBox = styled.div`

  margin: 0 auto;
  padding: 48px 20px 0;
  text-align: left;

  @media (min-width: ${laptop}){
    & {
        max-width: 100%;
        margin: 0;
        padding: 60px 80px 30px;
    }
  }
  @media (min-width: ${desktop}) {
    & {
      padding: 80px 80px 30px;
    }
  }


`;
const LeftSideBannerTitle = styled(Jost12Regular)`
  font-size: 40px;
  color :${standardBlack};
  line-height: 140% !important;
`;

const LeftSideBannerArtWork = styled.div`
  display: none;
  @media (min-width: ${laptop}){
    & {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
  }
`;

const LeftSideBannerArtWorkBgImage = styled.div`
background-image: url(${process.env.DEPLOY_ORIGIN}/asset/images/signBanner.webp);
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
 

  @media (max-width: ${laptop}), (max-height: ${mobileLandscapeWidth}){
    & {
        display: none;
    }
  }

  @media (min-height: ${laptop}}){
    & {
        max-height: 70%;
      }
    }
  `;

const LeftSideBannerArtWorkFooter = styled.div`
  margin: 0;
  padding: 90px 0;
  color: inherit;
  font-size: 14px;
  line-height: 14px;


  @media (max-height: ${laptop}){
    & {
      padding: 0;
    }
  }
`;

function LeftSideBanner() {
  return (
    <LeftSideBannerWrapper>
      <LeftSideBannerContent>
        <LeftSideBannerTitleBox>
          <LeftSideBannerTitle>Find Everything You Need To Learn Korean.</LeftSideBannerTitle>
        </LeftSideBannerTitleBox>
        <LeftSideBannerArtWork>
          <LeftSideBannerArtWorkBgImage />
          <LeftSideBannerArtWorkFooter />
        </LeftSideBannerArtWork>
      </LeftSideBannerContent>
    </LeftSideBannerWrapper>
  );
}

export default LeftSideBanner;
