import { OAUTH_ENDPOINT } from '../../config';
import { ProviderAuthToken } from '../interface/auth/Auth';

/**
 * google developer : https://console.developers.google.com/apis/credentials?project=social-glogin-25700
 */

export type GoogleAuthToken = ProviderAuthToken<'google'>;

export const googleLoginImpl = async (): Promise<GoogleAuthToken> => {
  window.open(
    `${OAUTH_ENDPOINT}/request-url/google`,
    'test',
    'width=600,height=600,left=0,top=0,scrollbars=0',
  );
  return new Promise((resolve, reject) => {
    window.addEventListener('message', (event) => {
      if (event.data) {
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data);

          const payload : GoogleAuthToken = {
            type: 'google',
            ...data,
          };
          resolve(payload);
        }
      } else {
        reject(new Error('Google Login Error!'));
      }
    });
  });
};
