import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

const config : RawAxiosRequestHeaders = {
  'Content-Type': 'application/json',
  Accept: '*/*',
};
const abortController = new AbortController();

const axiosRequestConfig :AxiosRequestConfig = {
  signal: abortController.signal, // Aborts request after 5 seconds
  headers: config,
  withCredentials: true
};

export default axiosRequestConfig;
