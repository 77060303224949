import { isAxiosError } from 'axios';
import { createStore } from 'zustand';

export interface ErrorContainerStructure {
  errorResponse: string;
  handleError: (errorResponse : unknown, email? : boolean) => void;
  resetError : () => void;
}
export const ErrorContainer = createStore<ErrorContainerStructure>((set) => ({
  errorResponse: '',
  handleError(error: unknown) {
    if (isAxiosError(error)) {
      const errorResponse = error.response?.data.message ?? error.message ?? '';
      set((state) => ({
        ...state,
        errorResponse,
      }));
    } else {
      set((state) => ({
        ...state,
        errorResponse: error as string,
      }));
    }
  },
  resetError() {
    set((state) => ({
      ...state,
      errorResponse: '',
    }));
  }
}));
