import { standardBlack } from '../../core/ColorList';

function PasswordSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 18} height={props.height ?? 18} viewBox="0 0 18 18" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_739_1475)">
        <path d="M12 6.66634V4.33301C12 2.67615 10.6569 1.33301 9 1.33301C7.34315 1.33301 6 2.67615 6 4.33301V6.66634M3 6.66634H15V14.6663H3V6.66634Z" stroke={props.color ?? standardBlack} strokeWidth="1.5" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_739_1475">
          <rect width="17" height="17" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PasswordSvg;
