import { Route, RouteProps, Routes } from 'react-router-dom';
import ErrorBoundary from '../components/extra/ErrorBoundary';
import LoginPage from '../pages/Login';
import MigrateEmailPage from '../pages/MigrateEmail';
import RegisterCompletePage from '../pages/RegisterComplete';
import VerifiedEmailPage from '../pages/VerifiedEmail';
import VerifiedEmailExpiredPage from '../pages/VerifiedEmailExpired';
import VerifyEmailPage from '../pages/VerifyEmail';

const RouteSet:RouteProps[] = [{
  path: '*',
  element: <LoginPage />
}, {
  path: '/sign-in',
  element: <LoginPage />
},
{
  path: '/sign-up-complete',
  element: <RegisterCompletePage />
}, {
  path: '/confirm',
  element: <VerifyEmailPage />
},
{
  // @TODO auth code 없을 경우 Error check
  path: '/verify-email/:auth?',
  element: <MigrateEmailPage />
},
{
  path: '/verified-email-expired',
  element: <VerifiedEmailExpiredPage />
},
{
  path: '/verified-email',
  element: <VerifiedEmailPage />
}];
function EntryRoute() {
  return (
    <ErrorBoundary>
      <Routes>
        {
      RouteSet.map((route, index) => (
        <Route
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          path={route.path}
          element={route.element}
        />
      ))
      }
      </Routes>
    </ErrorBoundary>
  );
}

export default EntryRoute;
