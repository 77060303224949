import { object, string } from 'yup';
import { VerifyEmailInput } from '../hooks/interface/auth/Rest';
import castFormTag from '../utils/FormTag';

export const VerifyEmailForm = castFormTag<VerifyEmailInput>();

export const verifyEmailSchema = object().shape({
  email: string().required('Please enter your email.').email('The email address entered is invalid. Please check the formatting (e.g. email@domain.com).'),
});

export const defaultVerifyEmailValues: VerifyEmailInput = {
  email: '',
};
