function InfoSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 20} height={props.height ?? 20} viewBox="0 0 20 20" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8191 5.8138L10.6368 11.7102H8.96689L8.78457 5.8138H10.8191ZM8.73899 13.3552C8.7307 12.7793 9.21551 12.3069 9.79976 12.3069C10.3633 12.3069 10.8564 12.7793 10.8605 13.3552C10.8564 13.9436 10.3633 14.416 9.79976 14.416C9.21551 14.416 8.7307 13.9436 8.73899 13.3552Z" fill="#222222" />
      <rect x="1.79999" y="1.79999" width="16" height="16" rx="8" stroke="#222222" strokeWidth="1.5" />
    </svg>
  );
}

export default InfoSvg;
