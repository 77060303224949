import { skyBlue } from '../../core/ColorList';

function SpinnerIndicatorSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 162} height={props.height ?? 162} viewBox="0 0 162 162" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1488_936)">
        <path d="M10.7313 62.7546L12.5115 65.4328L15.8647 66.3591L14.941 69.6845L0 65.5556L0.923667 62.2301L7.96593 64.1774L2.58067 56.271L3.71706 52.1979L8.85601 59.9145L18.7476 56.0144L17.5945 60.1656L10.7313 62.7546Z" fill={props.fill ?? skyBlue}  />
        <path d="M18.9435 43.8336C21.972 45.3513 23.0525 48.6545 21.5074 51.7233C19.9624 54.7921 16.654 55.8969 13.6255 54.3792C10.5969 52.8615 9.51653 49.5584 11.0616 46.4896C12.6066 43.4207 15.915 42.316 18.9435 43.8336ZM15.0977 51.461C16.626 52.2255 18.2046 51.8516 18.9211 50.4232C19.6377 49.0004 19.0051 47.5106 17.4713 46.7462C15.943 45.9818 14.3644 46.3557 13.6478 47.784C12.9313 49.2069 13.5639 50.6966 15.0977 51.461Z" fill={props.fill ?? skyBlue}  />
        <path d="M15.9319 38.5109L17.936 35.788L18.854 36.4631C18.5293 35.403 18.7197 34.2703 19.565 33.1265C19.9344 32.6243 20.2031 32.323 20.5838 32.0608L23.1701 33.9021C22.6886 34.1364 22.2352 34.5326 21.8378 35.0682C20.7685 36.5189 20.9533 37.9976 22.3192 38.9963L26.9207 42.3609L24.9166 45.0837L15.9319 38.5109Z" fill={props.fill ?? skyBlue}  />
        <path d="M25.857 34.3931C23.4778 31.9882 23.461 28.5679 25.885 26.1854C28.3089 23.8029 31.7293 23.8531 34.1196 26.2691C34.3491 26.4978 34.5842 26.7712 34.8249 27.0949L29.0142 32.8084C30.1114 33.5394 31.2702 33.4389 32.1771 32.5462C32.8657 31.8655 33.1959 31.062 33.028 30.4873L35.4519 28.1048C36.5435 30.0186 36.0117 32.5908 34.086 34.4823C31.6677 36.8649 28.2473 36.8146 25.857 34.3931ZM30.9903 27.8035C29.8539 27.0558 28.7176 27.1562 27.7715 28.088C26.8198 29.0198 26.7359 30.119 27.4692 31.2629L30.9903 27.8035Z" fill={props.fill ?? skyBlue}  />
        <path d="M35.6479 24.9691C33.7838 22.1514 34.2932 18.8817 36.9634 17.1297C38.1894 16.3262 39.3593 16.17 40.4062 16.5494L39.7792 15.6008L42.6062 13.7428L48.7416 23.0274L45.9146 24.8854L45.2876 23.9368C45.226 25.0472 44.6159 26.0571 43.3899 26.8662C40.7253 28.6126 37.512 27.7924 35.6479 24.9691ZM42.8973 20.2096C41.9568 18.7812 40.3838 18.3404 39.0011 19.2443C37.6464 20.1315 37.4728 21.7273 38.4133 23.1501C39.3538 24.5785 40.8932 25.0416 42.2479 24.1545C43.6306 23.2505 43.8377 21.638 42.8973 20.2096Z" fill={props.fill ?? skyBlue}  />
        <path d="M46.3794 11.8176L49.5199 10.5511L49.9453 11.6056C50.2252 10.5287 50.9865 9.66947 52.3133 9.1394C54.7148 8.17412 57.0603 9.25657 58.2135 12.1022L60.6319 18.0669L57.4914 19.3334L55.2858 13.8989C54.6532 12.331 53.668 11.7284 52.4868 12.2026C51.2496 12.6992 50.9026 13.9658 51.5519 15.5672L53.696 20.8623L50.5555 22.1289L46.3794 11.8176Z" fill={props.fill ?? skyBlue}  />
        <path d="M76.0037 0L76.2836 12.4036L84.0256 12.2306L84.0928 15.2827L72.8856 15.5338L72.5386 0.0781153L76.0037 0Z" fill={props.fill ?? skyBlue}  />
        <path d="M85.5428 9.79219C86.069 6.45555 88.8176 4.40781 92.182 4.93788C95.5464 5.46795 97.5392 8.22988 97.013 11.5888C96.9627 11.9069 96.8899 12.2584 96.7723 12.6434L88.7056 11.3824C88.7672 12.6992 89.5341 13.5696 90.7993 13.7649C91.7565 13.9156 92.6018 13.7035 92.9657 13.2181L96.3301 13.7426C95.4344 15.7513 93.0441 16.856 90.3794 16.4431C87.0094 15.9131 85.0166 13.1456 85.5428 9.79219ZM93.9118 9.9819C93.8334 8.62604 93.0832 7.77793 91.7677 7.57148C90.4522 7.36503 89.5117 7.9509 89.0247 9.21748L93.9118 9.9819Z" fill={props.fill ?? skyBlue}  />
        <path d="M98.961 12.0355C100.137 8.86626 103.076 7.34301 106.076 8.4422C107.453 8.94995 108.27 9.79806 108.59 10.8638L108.987 9.79806L112.167 10.9698L108.304 21.3982L105.124 20.2265L105.522 19.1607C104.587 19.7689 103.411 19.8805 102.034 19.3728C99.0282 18.2736 97.7911 15.2048 98.961 12.0355ZM107.106 15.0318C107.699 13.4304 107.128 11.9072 105.572 11.3325C104.055 10.7745 102.661 11.578 102.068 13.1793C101.475 14.7807 102.012 16.2928 103.529 16.8508C105.085 17.4255 106.513 16.6387 107.106 15.0318Z" fill={props.fill ?? skyBlue}  />
        <path d="M115.755 12.8721L118.683 14.5684L118.106 15.5504C119.131 15.1152 120.278 15.1877 121.51 15.9019C122.053 16.2144 122.378 16.4487 122.68 16.8002L121.112 19.5566C120.827 19.1046 120.385 18.6973 119.808 18.3625C118.246 17.4586 116.791 17.799 115.94 19.2553L113.068 24.1765L110.14 22.4803L115.755 12.8721Z" fill={props.fill ?? skyBlue}  />
        <path d="M125.255 18.6027L127.858 20.762L127.13 21.638C128.216 21.3758 129.33 21.638 130.427 22.5475C132.42 24.1935 132.577 26.7657 130.607 29.1315L126.481 34.0751L123.878 31.9157L127.634 27.4074C128.714 26.1073 128.787 24.9579 127.808 24.1433C126.783 23.2952 125.501 23.5853 124.393 24.9133L120.732 29.3045L118.129 27.1451L125.255 18.6027Z" fill={props.fill ?? skyBlue}  />
        <path d="M130.002 38.176L127.724 35.6763L136.009 28.1716L138.287 30.6713L130.002 38.176ZM140.996 28.7352C140.112 29.5387 138.875 29.4829 138.063 28.5957C137.257 27.7085 137.313 26.481 138.203 25.6719C139.088 24.8685 140.325 24.9243 141.136 25.8114C141.942 26.6986 141.881 27.9317 140.996 28.7352Z" fill={props.fill ?? skyBlue}  />
        <path d="M140.856 33.5169L142.76 36.3067L141.814 36.9484C142.928 36.993 143.93 37.5566 144.736 38.7283C146.191 40.8597 145.631 43.3762 143.079 45.1059L137.744 48.7215L135.841 45.9317L140.7 42.6341C142.099 41.6855 142.485 40.5975 141.769 39.5485C141.019 38.4493 139.703 38.3768 138.27 39.3477L133.534 42.5615L131.631 39.7717L140.856 33.5169Z" fill={props.fill ?? skyBlue}  />
        <path d="M137.873 50.7748L139.205 53.9217C138.606 54.3569 138.438 55.1325 138.925 56.2819C139.474 57.5764 140.683 58.0953 141.965 57.5596L143.051 57.1021C141.97 56.8343 141.08 56.0587 140.509 54.7084C139.267 51.7735 140.593 48.8051 143.611 47.5385C146.678 46.2496 149.774 47.3544 151.017 50.2949C151.588 51.6396 151.526 52.8169 150.961 53.7766L152.013 53.3358L153.329 56.4493L143.247 60.6898C140.459 61.8615 137.593 60.4555 136.294 57.3922C135.007 54.3457 136.031 51.9409 137.873 50.7748ZM146.986 55.5119C148.509 54.8702 149.203 53.4251 148.559 51.9074C147.932 50.4232 146.426 49.9434 144.898 50.585C143.37 51.2267 142.665 52.6328 143.297 54.1226C143.941 55.6346 145.458 56.1535 146.986 55.5119Z" fill={props.fill ?? skyBlue}  />
        <path d="M154.292 79.1586L154.129 77.0383L145.911 77.6521L145.659 74.282L153.877 73.6682L153.737 71.8158L156.643 71.5981L156.783 73.4506L157.885 73.3669C160.069 73.2051 161.787 74.4438 161.966 76.8486C162.011 77.4177 162.011 77.9701 161.966 78.3886L159.139 78.6006C159.156 78.3495 159.128 77.9757 159.111 77.7302C159.061 77.0662 158.613 76.7035 157.947 76.7537L157.035 76.8207L157.197 78.941L154.292 79.1586Z" fill={props.fill ?? skyBlue}  />
        <path d="M150.893 92.4326C147.518 92.1759 145.256 89.5256 145.525 86.1052C145.788 82.6793 148.425 80.414 151.806 80.6707C155.182 80.9329 157.443 83.5777 157.174 86.998C156.906 90.4239 154.269 92.6948 150.893 92.4326ZM151.548 83.9236C149.841 83.7953 148.526 84.7382 148.402 86.3284C148.279 87.9186 149.432 89.0513 151.14 89.1852C152.847 89.3191 154.163 88.3706 154.286 86.7804C154.409 85.1902 153.256 84.0519 151.548 83.9236Z" fill={props.fill ?? skyBlue}  />
        <path d="M155.702 96.2382L154.88 99.5135L153.771 99.2345C154.476 100.094 154.728 101.215 154.381 102.593C154.23 103.202 154.096 103.581 153.844 103.966L150.748 103.235C151.101 102.833 151.375 102.298 151.538 101.65C151.974 99.904 151.241 98.604 149.601 98.1967L144.059 96.8129L144.881 93.5377L155.702 96.2382Z" fill={props.fill ?? skyBlue}  />
        <path d="M151.269 121.017L149.634 123.394L137.559 124.51L145.408 129.872L143.448 132.718L130.657 123.98L132.292 121.603L144.366 120.487L136.518 115.125L138.477 112.279L151.269 121.017Z" fill={props.fill ?? skyBlue}  />
        <path d="M133.249 128.957C135.566 131.418 135.499 134.838 133.014 137.165C130.528 139.491 127.113 139.352 124.785 136.88C124.561 136.646 124.331 136.367 124.096 136.038L130.047 130.469C128.966 129.71 127.807 129.783 126.878 130.653C126.173 131.317 125.82 132.11 125.977 132.69L123.491 135.017C122.45 133.08 123.044 130.519 125.008 128.678C127.505 126.346 130.92 126.48 133.249 128.957ZM127.959 135.418C129.078 136.188 130.209 136.121 131.183 135.212C132.151 134.302 132.269 133.209 131.564 132.043L127.959 135.418Z" fill={props.fill ?? skyBlue}  />
        <path d="M111.187 151.856L108.758 140.384L111.697 138.666L116.668 143.129L115.162 136.64L118.084 134.933L126.945 142.638L123.861 144.446L118.52 139.274L120.48 146.422L117.49 148.168L112.15 142.957L114.098 150.154L111.187 151.856Z" fill={props.fill ?? skyBlue}  />
        <path d="M85.2685 149.507L91.2415 148.821L91.9748 145.719L95.5295 145.311L91.3367 161.358L87.7652 161.771L80.0063 147.097L83.8466 146.656L85.2685 149.507ZM86.6735 152.364L89.2206 157.497L90.5306 151.923L86.6735 152.364Z" fill={props.fill ?? skyBlue}  />
        <path d="M79.6088 152.475C79.2897 155.84 76.8434 158.066 73.6582 157.765C72.1971 157.626 71.1838 157.017 70.6073 156.069L70.0475 162L66.6719 161.682L68.1721 145.813L71.5477 146.131L71.4414 147.264C72.1915 146.438 73.2999 146.031 74.7554 146.165C77.9406 146.466 79.9279 149.111 79.6088 152.475ZM70.9711 151.661C70.8088 153.363 71.7548 154.691 73.4062 154.841C75.0185 154.992 76.1549 153.859 76.3172 152.163C76.4795 150.461 75.5727 149.139 73.9604 148.988C72.309 148.832 71.1279 149.965 70.9711 151.661Z" fill={props.fill ?? skyBlue}  />
        <path d="M52.4756 152.911L59.753 143.459L63.1285 144.457L64.069 156.331L60.5479 155.293L60.2232 147.638L55.7672 153.881L52.4756 152.911Z" fill={props.fill ?? skyBlue}  />
        <path d="M53.2088 147.219C51.6806 150.232 48.4338 151.343 45.394 149.814C42.3543 148.285 41.3075 145.038 42.8414 142.008C42.9869 141.718 43.1661 141.405 43.4012 141.076L50.6897 144.742C51.0368 143.47 50.5722 142.41 49.4302 141.835C48.5625 141.4 47.6948 141.344 47.2022 141.69L44.1625 140.161C45.6348 138.521 48.249 138.197 50.6618 139.413C53.7015 140.942 54.7427 144.184 53.2088 147.219ZM45.3045 144.474C44.9574 145.791 45.4164 146.823 46.6032 147.426C47.79 148.028 48.8648 147.755 49.7213 146.701L45.3045 144.474Z" fill={props.fill ?? skyBlue}  />
        <path d="M37.2881 144.999L34.6067 142.934L35.3064 142.036C34.2316 142.337 33.1064 142.114 31.9756 141.244C29.9267 139.664 29.6804 137.103 31.5614 134.671L35.5023 129.582L38.1838 131.647L34.5899 136.283C33.5542 137.617 33.5262 138.772 34.5339 139.547C35.5863 140.356 36.8626 140.022 37.9207 138.655L41.4194 134.135L44.1008 136.2L37.2881 144.999Z" fill={props.fill ?? skyBlue}  />
        <path d="M23.6458 128.94L25.1628 130.536L31.1471 124.89L33.4758 127.345L27.4916 132.991L29.0086 134.587L26.8926 136.585L25.3755 134.989L22.9684 137.26L20.6396 134.81L23.0468 132.539L21.5297 130.944L23.6458 128.94Z" fill={props.fill ?? skyBlue}  />
        <path d="M24.9781 125.196L19.6488 128.829L17.7343 126.039L22.5877 122.73C23.9872 121.776 24.3679 120.688 23.6514 119.639C22.9012 118.54 21.5857 118.473 20.1526 119.449L15.4223 122.674L13.5078 119.885L22.7165 113.607L24.631 116.397L23.6905 117.039C24.8045 117.084 25.8122 117.642 26.6183 118.813C28.0794 120.945 27.5252 123.461 24.9781 125.196Z" fill={props.fill ?? skyBlue}  />
        <path d="M11.6719 116.33L10.2556 113.262L11.2912 112.787C10.1996 112.559 9.30392 111.839 8.70494 110.55C8.44183 109.981 8.30748 109.601 8.2627 109.144L11.1345 107.782C11.1121 108.318 11.2352 108.904 11.5151 109.507C12.2709 111.141 13.6591 111.694 15.1986 110.991L20.3823 108.608L21.7986 111.677L11.6719 116.33Z" fill={props.fill ?? skyBlue}  />
        <path d="M12.8641 105.662C9.60045 106.561 6.59433 104.915 5.68186 101.639C4.77499 98.364 6.48238 95.4124 9.76279 94.5085C10.0763 94.4248 10.429 94.3466 10.8264 94.2908L13.004 102.136C14.1796 101.533 14.6554 100.479 14.314 99.2512C14.0564 98.3194 13.5134 97.6442 12.9201 97.5103L12.0132 94.235C14.2188 94.2183 16.2173 95.9313 16.9394 98.5258C17.8575 101.807 16.1501 104.758 12.8641 105.662ZM9.58925 97.9846C8.38009 98.6151 7.91546 99.6473 8.27373 100.925C8.6264 102.203 9.55007 102.817 10.9104 102.738L9.58925 97.9846Z" fill={props.fill ?? skyBlue}  />
        <path d="M6.3313 82.4897L6.6168 85.7426C5.94504 85.9156 5.59797 86.7302 5.68194 87.7123C5.7715 88.7333 6.19695 89.4364 6.68957 89.3973C7.23817 89.3471 7.4397 88.5883 7.57405 86.6689C7.77558 83.3545 8.75523 82.088 10.6697 81.9206C12.9481 81.7197 14.5603 83.9795 14.8066 86.7916C15.0642 89.6819 13.8158 92.003 11.4926 92.3211L11.2071 89.0681C11.9181 88.8896 12.3155 88.0191 12.226 86.959C12.1308 85.8821 11.6606 85.1233 11.1512 85.1679C10.6194 85.2126 10.3451 85.9993 10.2331 87.9355C10.0708 90.6193 9.41019 92.4271 7.15421 92.6279C4.99338 92.8177 3.42595 90.6862 3.17964 87.8741C2.93333 85.1121 4.14809 82.8133 6.3313 82.4897Z" fill={props.fill ?? skyBlue}  />
      </g>
      <defs>
        <clipPath id="clip0_1488_936">
          <rect width="162" height="162" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SpinnerIndicatorSvg;
