import styled from '@emotion/styled';
import { useCallback } from 'react';
import { charcoal, disabledBlack } from '../../core/ColorList';
import useSocialLoginAction from '../../hooks/action/use/useSocialLoginAction';
import { RestPolicyInput } from '../../hooks/interface/auth/Rest';
import { svgWrapperTouchableHOC } from '../base/Icon';
import {
  FlexCol, FlexRow
} from '../base/Layout';
import AppleLogoSvg from '../icon/AppleLogo';
import FacebookLogoSvg from '../icon/FacebookLogo';
import GoogleLogoSvg from '../icon/GoogleLogo';
import Modal from './Modal';
import TermsService from './TermsService';

const SocialLoginOptionsWrapper = styled(FlexRow)`
  justify-content: flex-end;
  gap :10px;
`;

function SocialLoginOptions() {
  const {
    onLogin, authToken, modalRef, approveCheckAgreement
  } = useSocialLoginAction();

  const GoogleSocialLoginButton = svgWrapperTouchableHOC(GoogleLogoSvg);
  const FacebookSocialLoginButton = svgWrapperTouchableHOC(FacebookLogoSvg);
  const AppleSocialLoginButton = svgWrapperTouchableHOC(AppleLogoSvg);

  /**
   * 약관 동의 후 로그인 요청
   * @param data RestPolicyInput
   * @returns void
   */
  const onAgree = useCallback(async (data: RestPolicyInput) => {
    if (authToken) {
      await approveCheckAgreement(authToken, data.isNewsletter);
    }
  }, [approveCheckAgreement, authToken]);

  const googleLogin = useCallback(async () => {
    await onLogin('google');
  }, [onLogin]);

  const facebookLogin = useCallback(async () => {
    await onLogin('facebook');
  }, [onLogin]);

  const appleLogin = useCallback(async () => {
    await onLogin('apple');
  }, [onLogin]);

  return (
    <>
      <SocialLoginOptionsWrapper flex={1}>
        <GoogleSocialLoginButton
          fill={charcoal}
          onClick={googleLogin}
        />
        <AppleSocialLoginButton
          fill={charcoal}
          onClick={appleLogin}
        />
        <FacebookSocialLoginButton
          fill={disabledBlack}
          disabled
          containerStyle={{
            cursor: 'not-allowed'
          }}
        />
      </SocialLoginOptionsWrapper>
      <Modal ref={modalRef}>
        <FlexCol>
          {/* <Pretandard14Bold>
            Sign up with your social account
          </Pretandard14Bold>
          <SpacingSmall>
            <Pretandard12Medium>
              If you sign up on our website, you can use the same sign-in information on our TTMIK: Audio app.
            </Pretandard12Medium>
            <NewSiteMigrationBanner />
          </SpacingSmall> */}
          <TermsService onSubmit={onAgree} scope="social" />
        </FlexCol>
      </Modal>
    </>

  );
}

export default SocialLoginOptions;
