import { skyBlue } from '../../core/ColorList';
import { Spinner, SpinnerBg } from '../molecules/Spinner';

export function LoadingSpinner() {
  return <Spinner size={162} color={skyBlue} />;
}

export function LoadingSpinnerBg() {
  return <SpinnerBg size={162} color={skyBlue} />;
}
