import styled from '@emotion/styled';
import { memo, PropsWithChildren } from 'react';
import { coolGray, standardBlack, UiColorList } from '../../core/ColorList';
import { FlexViewStyledProps } from './Core';

/* width */
export const desktop  = '1200px';

export const laptop  = '960px';
export const tablet  = '768px';
export const mobileLandscapeWidth = '640px';

export const FlexView = styled.div<FlexViewStyledProps>`
    display: flex;
    flex: ${(props: FlexViewStyledProps) => props.flex};
`;

export const FlexRow = styled.div<FlexViewStyledProps>`
    display: flex;
    flex-direction : row;
    flex: ${(props: FlexViewStyledProps) => props.flex};
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction : column;
`;

export const FlexColAlignStretchBox = styled(FlexCol)`
    align-items: stretch;
    width: 100%;
`;
export const FlexRowAlignCenterBox = styled(FlexRow)`
  align-items: center;
  width: 100%;
`;

export const FlexColAlignCenterBox = styled(FlexCol)`
    align-items: center;
    width: 100%;
`;

export const FlexColAlignStart = styled(FlexCol)`
    align-items: flex-start;
    width: 100%;
`;

export const FlexColCenteredBox = styled(FlexCol)`
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const TextCenteredBox = styled.div`
  text-align:center;
`;

export const ContainerWrapperBox = memo(styled(FlexColAlignCenterBox)`
  background: #fff;
  z-index: 1;
  flex : 1;
  min-height: 100vh;
`);

export const BannerContainerWrapperBox = memo(styled(ContainerWrapperBox)`
  margin-left: 450px;
    
  @media (max-width: ${laptop}){
    & {
      margin-left: 0;
    }
  }
`);

export const ContainerInnerBox = memo(styled(FlexCol)`
  width: 100%;
  max-width: 360px;
  margin: 5% 20px;
  font-family: 'Pretendard';
`);

type SpacingSize = {
  top: number;
};
export const Spacing = styled.div<SpacingSize>`
    margin-top: ${({ top }) => `${top}px`};
    width : 100%;
`;

export function SpacingSmall({ children }: PropsWithChildren<unknown>) {
  return <Spacing top={8}>{children}</Spacing>;
}
export function SpacingMedium({ children }: PropsWithChildren<unknown>) {
  return <Spacing top={16}>{children}</Spacing>;
}
export function SpacingLarge({ children }: PropsWithChildren<unknown>) {
  return <Spacing top={24}>{children}</Spacing>;
}
export function SpacingXLarge({ children }: PropsWithChildren<unknown>) {
  return <Spacing top={32}>{children}</Spacing>;
}
export function SpacingXXLarge({ children }: PropsWithChildren<unknown>) {
  return <Spacing top={80}>{children}</Spacing>;
}

export const Line = styled.div<{
  borderColor: UiColorList;
}>`
    width: 100%;
    height: 1px;
    background: ${(props) => props.borderColor};
 `;

export const DotStyledList = styled.ul`
    list-style: dot;
    list-style-position: outside;
`;

export const CircleStyledList = styled.ul`
    list-style: circle;
    list-style-position: outside;

    li{
        margin-top: 10px;
        font-size: 14px;
        font-family: 'Pretendard';
        font-weight: 400;
        line-height: 140%;

        span{
          margin-left: 10px;
          display : block;
        }
    }
`;

export const SignNotice = styled.div`
  background-color : ${coolGray};
  padding : 20px;
  border-radius : 8px;
  margin-bottom : 30px;
  letter-spacing : -0.14px;
  line-height : 150%;
  word-break : word-all;
  color: ${standardBlack};
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;
