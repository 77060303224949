import { OAUTH_ENDPOINT } from '../../config';
import { ProviderAuthToken } from '../interface/auth/Auth';

/**
 * facebook developer :https://developers.facebook.com/apps/
 */

export type FacebookAuthToken = ProviderAuthToken<'facebook'>;
export const FacebookLoginImpl = async (): Promise<FacebookAuthToken> => {
  window.open(
    `${OAUTH_ENDPOINT}/request-url/facebook`,
    'test',
    'width=600,height=600,left=0,top=0,scrollbars=0',
  );
  return new Promise((resolve, reject) => {
    window.addEventListener('message', (event) => {
      if (event.data) {
        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data);

          const payload : FacebookAuthToken = {
            type: 'facebook',
            ...data,
          };
          resolve(payload);
        }
      } else {
        reject(new Error('Facebook Login Error!'));
      }
    });
  });
};
