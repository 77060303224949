import styled from '@emotion/styled';
import { memo } from 'react';
import { useToggle } from 'usehooks-ts';
import {
  errorColor, grayItem, standardBlack
} from '../../../core/ColorList';
import { FormInputIconProps, InputBaseProps } from '../../../utils/FormUtils';
import { svgWrapperHOC, svgWrapperTouchableHOC } from '../../base/Icon';
import { FlexCol, SpacingSmall } from '../../base/Layout';
import { ErrorText } from '../../base/Typo';
import CloseEyesSvg from '../../icon/CloseEyes';
import OpenEyesSvg from '../../icon/OpenEyes';

export const LabeledInputRowWrapper = styled.div<InputBaseProps>`
    display: flex;
    border: none;
    border-bottom: 1px solid;
    border-bottom-color: ${({ errors }) => (errors ? errorColor : grayItem)};
    flex-direction: row;
    width: 100%;
    height: 40px;
 
`;

export const InputStyled = styled.input`
  padding-left: 20px;
  flex: 1;
  text-align: left;
  justify-content: center;
  border : 0;
  font-family: 'Pretendard';
  font-size : 14px;
`;

function FormLabelInput<Data>({
  icon,
  secure,
  title,
  name,
  register,
  ...props
}: FormInputIconProps<Data>) {
  const [isShow, toggle] = useToggle(false);
  // eslint-disable-next-line no-nested-ternary
  const inputType = secure ? (isShow ? 'text' : 'password') : 'text';
  const ConvertedIcon = memo(svgWrapperHOC(icon));
  const HiddenPasswordBtn = memo(svgWrapperTouchableHOC(isShow ? OpenEyesSvg : CloseEyesSvg));
  return (
    <FlexCol>
      <LabeledInputRowWrapper errors={props.errors}>
        <ConvertedIcon width="20px" height="20px" color={props.errors ? errorColor : standardBlack} />
        <InputStyled type={inputType} placeholder={title} {...register} id={name}  />
        {secure && <HiddenPasswordBtn onClick={toggle} width={30} height={30} />}
      </LabeledInputRowWrapper>
      <SpacingSmall>
        {props.errors && <ErrorText>{props.errors.message}</ErrorText>}
      </SpacingSmall>
    </FlexCol>
  );
}

export default FormLabelInput;
