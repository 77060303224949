import axios, { AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { ModalAction } from '../../../components/organisms/Modal';
import { REST_ENDPOINT } from '../../../config';
import { afterLoginRedirectUrl } from '../../../lib/constants';
import { ErrorContainer } from '../../container/Error';
import { LoadingContainer } from '../../container/Loading';
import { UserInfoContainer } from '../../container/User';
import { CheckAgreementResponse } from '../../interface/auth/Auth';
import { LoginErrorCode } from '../../interface/auth/Error';
import { RestLoginInput } from '../../interface/auth/Rest';
import axiosRequestConfig from '../xhr';

const useLoginAction = () => {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState<RestLoginInput | null>(null);

  const flagLoading = useStore(
    LoadingContainer,
    (container) => container.flagLoading,
  );

  const [errorResponse, handleError] = useStore(
    ErrorContainer,
    (container) => [container.errorResponse, container.handleError],
  );

  const setEmail = useStore(
    UserInfoContainer,
    (container) => container.setEmail,
  );

  const modalRef = useRef<ModalAction>(null);

  const openModal = useCallback(async () => {
    const modalAction = modalRef.current;
    if (modalAction) {
      modalAction.open();
    }
  }, []);

  const closeModal = useCallback(async () => {
    const modalAction = modalRef.current;
    if (modalAction) {
      modalAction.close();
    }
  }, []);

  /**
   * 약관 동의 여부 체크
   * @param data RestLoginInput
   * @returns void
   */
  const requestCheckAgreement = useCallback(async (data : RestLoginInput) => {
    await axios.post<{}, AxiosResponse<CheckAgreementResponse>>(`${REST_ENDPOINT}/check-agreement`, { email: data.email, referer: 'talktomeinkorean' }, axiosRequestConfig).then((res) => {
      if (res.data.needAgree) {
        // 약관 동의가 필요하면 모달창을 띄움
        openModal();
      }
    }).catch((error) => {
      handleError(error);
      closeModal();
    }).finally(() => {
      flagLoading(false);
    });
  }, [closeModal, flagLoading, handleError, openModal]);

  /**
   * 로그인 요청
   * @param data RestLoginInput
   * @returns Promise<void>
   */

  const requestLogin = useCallback(async (data : RestLoginInput) => {
    flagLoading(true);
    try {
      await axios.post<{}, AxiosResponse>(`${REST_ENDPOINT}/login`, data, axiosRequestConfig);
      window.location.replace(afterLoginRedirectUrl);
    } catch (error: unknown) {
      flagLoading(false);
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;

        switch (errorData.code as LoginErrorCode) {
          case 'USER_MIGRATION_REQUIRE':
            navigate(`/verify-email/${errorData.data.authCode}`);
            break;
          case 'USER_VALIDATION_REQUIRE':
            navigate('/confirm');
            break;
          case 'USER_AGREEMENT_NEED':
            openModal();
            break;
          default:
            handleError(error);
        }
      }
      handleError(error);
    }
  }, [flagLoading, handleError, navigate, openModal]);

  /**
   * 약관 동의 여부 전송
   * @param data RestPolicyInput
   * @returns Promise<void>
   */
  const approveCheckAgreement = useCallback(async (data : RestLoginInput, isNewsletter : boolean) => {
    flagLoading(true);
    try {
      const payload = {
        email: data.email,
        isNewsletter,
        referer: data.referer
      };
      await axios.post<{}, AxiosResponse>(`${REST_ENDPOINT}/approve-agreement`, payload, axiosRequestConfig);
      closeModal();
      await requestLogin(data);
    } catch (error) {
      flagLoading(false);
      handleError(error);
    } finally {
      closeModal();
      flagLoading(false);
    }
  }, [closeModal, flagLoading, handleError, requestLogin]);

  /**
   * 로그인 요청
   * @param data RestLoginInput
   * @returns Promise<void>
   */
  const onLogin = useCallback(async (data: RestLoginInput) => {
    flagLoading(true);
    setEmail(data.email);
    setLoginInfo(data);

    try {
      await requestLogin(data);
    } catch (error: unknown) {
      flagLoading(false);
      handleError(error);
    }
  }, [flagLoading, handleError, requestLogin, setEmail]);

  return {
    onLogin,
    approveCheckAgreement,
    errorResponse,
    flagLoading,
    modalRef,
    requestLogin,
    closeModal,
    loginInfo,
  };
};

export default useLoginAction;
