import {
  boolean, object, ObjectSchema, ref, string
} from 'yup';
import { RefererArray, RestRegisterInput } from '../hooks/interface/auth/Rest';
import castFormTag from '../utils/FormTag';

export const RegisterForm = castFormTag<RestRegisterInput>();

const registerSchema:ObjectSchema<RestRegisterInput> = object({
  email: string().required('Please enter your email.').email('The email address entered is invalid. Please check the formatting (e.g. email@domain.com).'),
  password: string().required('Please enter your password.'),
  passwordConfirm: string().required('Please confirm your password.').oneOf([ref('password')], 'Password does not match.'),
  username: string().required('Please enter your name.').max(60),
  isAgreePolicy: boolean().required().oneOf([true], ''),
  isAdult: boolean().required().oneOf([true], ''),
  isTermsAndConditions: boolean().required().oneOf([true], ''),
  isNewsletter: boolean().default(false),
  referer: string().oneOf(RefererArray).required(),
}).strict(true);

export default registerSchema;
