import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  primaryColor, standardBlack, white
} from '../../core/ColorList';
import {
  ContainerInnerBox,
  ContainerWrapperBox,
  FlexColAlignCenterBox,
  FlexColAlignStretchBox,
  Spacing, SpacingMedium, SpacingXLarge
} from '../base/Layout';
import { TitleSmall600 } from '../base/Typo';
import HeaderTTMIKLogo from '../molecules/Logo';

// states success
const TitleSmall600Centered = styled(TitleSmall600)`
    text-align: center !important;
`;

const VertifiedEmailLink = styled(Link)`
  background-color: ${primaryColor};
  color: ${white};
  display: block;
  padding: 14px 27px;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  font-family: 'DM Sans';
  `;

function VerifiedEmailTemplate() {
  return (
    <FlexColAlignCenterBox>
      <ContainerWrapperBox>
        <ContainerInnerBox>
          <FlexColAlignCenterBox>
            <HeaderTTMIKLogo />
            <Spacing top={48}>
              <TitleSmall600Centered color={standardBlack}>
                Thank you for verifying your email.
              </TitleSmall600Centered>
              <SpacingXLarge />
              <FlexColAlignStretchBox>
                <VertifiedEmailLink to="/sign-in">
                  Sign In
                </VertifiedEmailLink>
                <SpacingMedium />
              </FlexColAlignStretchBox>
            </Spacing>
          </FlexColAlignCenterBox>
        </ContainerInnerBox>
      </ContainerWrapperBox>
    </FlexColAlignCenterBox>
  );
}

export default VerifiedEmailTemplate;
