import styled from '@emotion/styled';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { APP_PROTOCOL, SITE_ROOT } from '../../config';
import {
  grayDark, primaryColor, white
} from '../../core/ColorList';
import { mobileLandscapeWidth } from '../base/Layout';
import ErrorSvg from '../icon/Error';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width : 100%;
`;
const ErrorText = styled.p`
  margin-top: 20px;
  font-size: 28px;
  color: ${grayDark};
  text-align: center;
`;

const ErrorSubText = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: ${grayDark};
  text-align: center;
  line-height: 30px;
`;

const ErrorLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  text-align: center;
  gap: 30px;
  @media screen and (max-width: ${mobileLandscapeWidth}) {
    flex-direction: column;
    gap: 10px;
    width : 100%:
    flex: 1;
  }
`;

const ErrorContactUsLink = styled(Link)`
  background-color: ${primaryColor};
  color: ${white};
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;

  @media screen and (max-width: ${mobileLandscapeWidth}) {
    flex:1;
  }
`;

const ErrorGoHomeLink = styled.button`
  border: 1px solid ${primaryColor};
  color: ${primaryColor};
  background-color: ${white};
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;

  @media screen and (max-width: ${mobileLandscapeWidth}) {
    flex:1;
  }
`;

function ErrorTemplate() {
  const goHome = useCallback(() => {
    window.location.href  = `${`${APP_PROTOCOL}://${SITE_ROOT}/`}`;
  }, []);
  return (
    <ErrorWrapper>
      <ErrorSvg width="100%" />
      <ErrorText>앗!</ErrorText>
      <ErrorText>Something went wrong.</ErrorText>
      <ErrorSubText>
        Looks like the link is broken or you have entered a URL that doesn&apos;t
        exist.
        <br />
        Go back to our website or contact us if the problem persists.
      </ErrorSubText>
      <ErrorLinkBox>
        <ErrorGoHomeLink onClick={goHome} color={white}>
          Go Home
        </ErrorGoHomeLink>
        <ErrorContactUsLink to="https://talktomeinkorean.freshdesk.com/support/tickets/new" target="_blank">
          Contact Us
        </ErrorContactUsLink>
      </ErrorLinkBox>
    </ErrorWrapper>
  );
}
export default ErrorTemplate;
