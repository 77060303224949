import { standardBlack } from '../../core/ColorList';

function OpenEyesSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 36} height={props.height ?? 11} viewBox="0 0 36 11" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.25977 5.49073C11.2998 2.65986 14.345 0.86377 17.7598 0.86377C21.1745 0.86377 24.2198 2.65986 26.2598 5.49073M21.7141 5.56464C21.7141 7.74857 19.9437 9.51899 17.7598 9.51899C15.5758 9.51899 13.8054 7.74857 13.8054 5.56464C13.8054 3.38071 15.5758 1.61029 17.7598 1.61029C19.9437 1.61029 21.7141 3.38071 21.7141 5.56464ZM20.5537 4.16768C20.5537 4.9392 19.9282 5.56464 19.1567 5.56464C18.3852 5.56464 17.7598 4.9392 17.7598 4.16768C17.7598 3.39616 18.3852 2.77073 19.1567 2.77073C19.9282 2.77073 20.5537 3.39616 20.5537 4.16768Z" stroke={props.color ?? standardBlack} strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  );
}

export default OpenEyesSvg;
