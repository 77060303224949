import { memo } from 'react';
import { Link } from 'react-router-dom';
import { redirectLandingUrl } from '../../lib/constants';
import { svgWrapperHOC } from '../base/Icon';
import { FlexColAlignCenterBox } from '../base/Layout';
import LogoSvg from '../icon/Logo';

function HeaderTTMIKLogo() {
  const LogoLinkComponent = memo(svgWrapperHOC(LogoSvg));
  return (
    <FlexColAlignCenterBox>
      <Link to={redirectLandingUrl}>
        <LogoLinkComponent  />
      </Link>
    </FlexColAlignCenterBox>
  );
}

export default HeaderTTMIKLogo;
