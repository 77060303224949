import EntryRoute from './router/Router';

function AppEntry() {
  return <EntryRoute />;
}
function App() {
  return (
    <>
      <AppEntry />
      <div id="recaptcha-container" />
    </>
  );
}

export default App;
