/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore } from 'zustand';

export interface UserInfoContainerStructure {
  email: string;
  setEmail: (email : string) => void;
}

export const UserInfoContainer = createStore<UserInfoContainerStructure>((set) => ({
  email: '',
  setEmail(email:string) {
    set((state) => ({
      ...state,
      email,
    }));
  },
}));
