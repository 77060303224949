import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { errorColor, standardBlack, white } from '../../core/ColorList';
import { ColorViewStyledProps } from './Core';

export const TypoColorStyled = styled.span<
Partial<ColorViewStyledProps>
>`
     color: ${(props) => props?.color ?? standardBlack};
 `;

export const H1 = styled.h1`
    font-size: 25px;
    font-family: 'Poppins-SemiBold';
`;
export const H2 = styled.h2`
    font-size: 20px;
    font-family: 'Poppins-SemiBold';

`;
export const H3 = styled.h3`
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
`;

/* Poppins */

/* Inter */

export const Inter12Regular = styled.p`
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 300;
`;
export const Inter14Medium = styled.p`
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
`;
export const Inter14Bold = styled.p`
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 600;
`;

export const Inter18Medium = styled.p`
    font-size: 18px;
    font-family: 'Inter';
    font-weight: 400;
`;

export const Inter18Bold = styled.p`
    font-size: 18px;
    font-family: 'Inter';
    font-weight: 700;
`;

/* DM Sans */

export const DmSans12Regular = styled.p`
    font-size: 12px;
    font-family: 'Dm Sans';
    font-weight: 400;
`;
export const DmSans14Medium = styled.p`
    font-size: 14px;
    font-family: 'Dm Sans';
    line-height: 140%;
    font-weight: 500;
`;
export const DmSans14Bold = styled.p`
    font-size: 14px;
    font-family: 'Dm Sans';
    line-height: 140%;
    font-weight: 700;
`;

export const DmSans18Medium = styled.p`
    font-size: 18px;
    font-family: 'Dm Sans';
    line-height: 160%;
    font-weight: 500;
`;

export const DmSans18Bold = styled.p`
    font-size: 18px;
    font-family: 'Dm Sans';
    line-height: 160%;
    font-weight: 700;
`;

/* pretenard */

export const Pretandard12Regular = styled.p`
    font-size: 12px;
    font-family: 'Pretendard';
    font-weight: 400;
    line-height: 140%;
`;

export const Pretandard12Medium = styled.p`
    font-size: 12px;
    font-family: 'Pretendard';
    font-weight: 500;
    line-height: 140%;
`;

export const Pretandard12Bold = styled.p`
    font-size: 12px;
    font-family: 'Pretendard';
    font-weight: 600;
    line-height: 140%;
`;

export const Pretandard14Regular = styled.p`
    font-size: 14px;
    font-family: 'Pretendard';
    font-weight: 400;
    line-height: 140%;
`;

export const Pretandard14Bold = styled.p`
    font-size: 14px;
    font-family: 'Pretendard';
    font-weight: 600;
    line-height: 140%;
`;

export const Pretandard16Medium = styled.p`
    font-size: 16px;
    font-family: 'Pretendard';
    font-weight: 500;
`;

export const Pretandard18Semibold = styled.p`
    font-size: 18px;
    font-family: 'Pretendard';
    font-weight: 600;
`;

export const Pretandard18Bold = styled.p`
    font-size: 18px;
    font-family: 'Pretendard';
    font-weight: 600;
`;

/* Jost */

export const Jost12Regular = styled.p`
    font-size: 12px;
    font-weight: 300;
    line-height: 120%;
    font-family: 'Jost';
`;

export const ErrorText = styled.p`
    // text-align:center;
    color: ${errorColor};
    line-height : 140%;
    font-size : 14px;
`;

export const LinkText = styled(Link)<
Partial<ColorViewStyledProps>
>`
    text-decoration: none;
    color: ${(props) => props?.color ?? standardBlack};
    &:hover {
        text-decoration: underline;
    }
`;

export const LinkLineText = styled(Link)<
Partial<ColorViewStyledProps>
>`
    text-decoration: underline;
    color: ${(props) => props?.color ?? standardBlack};
`;

export const SmallParagraph = styled(Pretandard14Regular)`
    line-height: 140%;
`;

export const MediumParagraph = styled(Pretandard16Medium)`
    line-height: 160%;
`;
export const LargeParagraph = styled(Pretandard18Bold)`

`;

export const PillBadge = styled.span<
Partial<ColorViewStyledProps>
>`
    color: ${(props) => props?.color ?? white};
    border-radius: 30px;
    padding: 16px 32px;
    background-color: ${(props) => props?.backgroundColor ?? standardBlack};
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const TitleSmall600 = styled.h3<Pick<ColorViewStyledProps, 'color'>>`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    // text-align: left;
    color: ${(props) => props?.color ?? standardBlack};
`;

export const BodySmall500 = styled.p<Pick<ColorViewStyledProps, 'color'>>`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0em;
    // text-align: left;
    color: ${(props) => props?.color ?? standardBlack};
`;

export const CaptionLarge500 = styled.span<Pick<ColorViewStyledProps, 'color'>>`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: ${(props) => props?.color ?? standardBlack};
`;

export const CaptionLarge600 = styled.span<Pick<ColorViewStyledProps, 'color'>>`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: ${(props) => props?.color ?? standardBlack};
`;

export const UnderlinedText = styled.span<Pick<ColorViewStyledProps, 'color'>>`
    color: ${(props) => props?.color ?? standardBlack};
    text-decoration: underline;
`;

export const ScreenHeadingText = styled.h3`
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;

    &:focus{
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000; /* Above WP toolbar. */
    }
`;
