import VerifiedEmailTemplate from '../components/templates/VerifiedEmailTemplate';

// 이메일 인증 링크 클릭 시 이동하는 페이지
function VerifiedEmailPage() {
  return (
    <VerifiedEmailTemplate  />
  );
}

export default VerifiedEmailPage;
