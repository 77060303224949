import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useStore } from 'zustand';
import {
  coreBlack, primaryColor, standardBlack, white
} from '../../core/ColorList';
import useVertifyEmailAction from '../../hooks/action/use/useVertifyEmail';
import { LoadingContainer } from '../../hooks/container/Loading';
import { VerifyEmailInput } from '../../hooks/interface/auth/Rest';
import { VerifyEmailForm, defaultVerifyEmailValues, verifyEmailSchema } from '../../validator/VerifyEmail';
import { Button } from '../atoms/button/Button';
import {
  BannerContainerWrapperBox,
  CircleStyledList,
  ContainerInnerBox,
  FlexColAlignStretchBox, FlexRow, SpacingLarge, SpacingMedium, SpacingSmall
} from '../base/Layout';
import {
  BodySmall500,
  ErrorText,
  LinkLineText,
  TitleSmall600
} from '../base/Typo';
import EmailSvg from '../icon/Email';
import LeftSideBanner from '../molecules/LeftSideBanner';
import HeaderTTMIKLogo from '../molecules/Logo';
import { LoadingSpinnerBg } from '../organisms/LoadingSpinner';

function MigrateEmailTemplate() {
  const {
    register,
    handleSubmit,
    formState: {
      isSubmitting, errors, isValid
    },
  } = useForm<VerifyEmailInput>({
    resolver: yupResolver(verifyEmailSchema),
    mode: 'onChange',
    defaultValues: defaultVerifyEmailValues,
  });

  // 이메일 마이그레이션 페이지
  const {
    onVertify, errorResponse,
  } = useVertifyEmailAction();

  const isLoading = useStore(
    LoadingContainer,
    (container) => container.isLoading,
  );
  return (
    <>
      {isLoading ? <LoadingSpinnerBg /> : null}
      <FlexRow flex={1}>
        <LeftSideBanner />
        <BannerContainerWrapperBox>
          <ContainerInnerBox>
            <HeaderTTMIKLogo />
            <SpacingLarge>
              <SpacingMedium />
              <TitleSmall600 color={standardBlack}>Please enter an alternative email address.</TitleSmall600>
              <SpacingSmall />
              <BodySmall500 color={standardBlack}>
                The email address you provided cannot be verified in our system.
                Please enter a different email address and it will become your sign-in email address for our website.
              </BodySmall500>
              <SpacingLarge />
              <FlexColAlignStretchBox>
                <form onSubmit={handleSubmit(onVertify)}>
                  <VerifyEmailForm.LabelInput
                    icon={EmailSvg}
                    name="email"
                    title="Email"
                    register={register('email')}
                    errors={errors.email}
                  />
                  <SpacingLarge>
                    <Button
                      type="submit"
                      disabled={!isValid}
                      backgroundColor={primaryColor}
                      color={white}
                      rounded={10}
                    >
                      Verify
                    </Button>
                  </SpacingLarge>
                </form>
                <SpacingMedium>
                  {errorResponse && (<ErrorText>{errorResponse}</ErrorText>)}
                </SpacingMedium>
                <SpacingLarge />
                <CircleStyledList>
                  <li>
                    <span color={standardBlack}>
                      The verification link will be valid up to 1 hour.
                    </span>
                  </li>
                  <SpacingSmall />
                  <li>
                    <span color={standardBlack}>
                      If you don&apos;t see any mail from us, please also check your spam box.
                    </span>
                  </li>
                  <SpacingSmall />
                  <li>
                    <span color={standardBlack}>
                      If you have any issues with the email verification process, please
                      {' '}
                      <LinkLineText color={coreBlack} to="https://talktomeinkorean.freshdesk.com/support/tickets/new" target="_blank">contact us</LinkLineText>
                      .
                    </span>
                  </li>
                </CircleStyledList>
              </FlexColAlignStretchBox>
            </SpacingLarge>
          </ContainerInnerBox>
        </BannerContainerWrapperBox>
      </FlexRow>
    </>
  );
}

export default MigrateEmailTemplate;
