import { Global } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorBoundary from './components/extra/ErrorBoundary';
import { RECAPTCHA_V3_SITE_KEY } from './config';
import Reset from './core/Reset';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_V3_SITE_KEY}
        language="en"
        useEnterprise
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined
        }}
        useRecaptchaNet
        container={{
          element: 'recaptcha-container',
          parameters: {
            badge: 'bottomleft',
            theme: 'light',
            tabindex: 9999
          }
        }}
      >
        <Global styles={Reset} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
