function GoogleLogoSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 39} height={props.height ?? 39} viewBox="0 0 39 39" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="39" height="39" rx="8" />
      <path d="M20.3005 18.825V20.875H25.2005C25.0505 22.025 24.6674 22.8669 24.0836 23.4581C23.3668 24.175 22.2505 24.9581 20.3005 24.9581C17.2836 24.9581 14.9255 22.525 14.9255 19.5081C14.9255 16.4912 17.2836 14.0581 20.3005 14.0581C21.9255 14.0581 23.1174 14.7 23.9924 15.525L25.4343 14.0831C24.2174 12.9 22.5836 12 20.3005 12C16.1674 12 12.6924 15.3669 12.6924 19.5C12.6924 23.6331 16.1674 27 20.3005 27C22.5336 27 24.2174 26.2669 25.5336 24.9C26.8836 23.55 27.3086 21.6419 27.3086 20.1081C27.3086 19.6331 27.2755 19.1912 27.2005 18.825H20.3005Z" fill="white" />
    </svg>
  );
}

export default GoogleLogoSvg;
