import { standardBlack } from '../../core/ColorList';

function EmailSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={props.width ?? 18} height={props.height ?? 18} viewBox="0 0 18 18" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4V14.6667H3L3 4M15 4L3 4M15 4L9 9.33333L3 4" stroke={props.color ?? standardBlack} strokeWidth="1.5" />
    </svg>
  );
}

export default EmailSvg;
